import { AXIOS_INSTANCE } from "../../service";
import { BASE_URL } from "../../utils/constants";

export const generateMFACode = async (password) => {
  try {
    const res = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/generate-mfacode`,
      password
    );
    return res;
  } catch (error) {
    return error;
  }
};
