import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import CustomInput from "../Input";
import { Encrypt } from "../../utils/constants";
import { useState } from "react";
import { registerAPI } from "../../service/AuthService";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import "./login.scss";

export default function SignUpCard() {
  const navigate = useNavigate();
  const toast = useToast();
  const [fullName, setFullName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [refferalCode, setRefferalCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [ageCheck, setAgeCheck] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ageAgree, setAgeAgree] = useState(false);

  const signUpHandler = async () => {
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!mobile) {
      return toast({
        title: "Warning",
        description: "Mobile number is required!",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }

    if (!ageAgree) {
      return toast({
        title: "Warning",
        description: "Minimum age 18 is required!",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }

    const res = await registerAPI({
      // fullName,
      // email,
      mobile,
      username,
      refferalCode,
      password: Encrypt(password),
      transactionPassword: "172586",
    });
    if (res.code === 200) {
      navigate("/");
      toast({
        title: "Success",
        description: "Success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (res.code === 403) {
      toast({
        title: "Error",
        description: res?.response || "Somthing went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (res.code === 401) {
      toast({
        title: "Error",
        description: res?.response || "Unauthorized, Enter Correct credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (res.code === 500) {
      toast({
        title: "Error",
        description: res?.response || "Unauthorized, Enter Correct credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: res?.error || "Something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={2} fontSize="sm" mx={"auto"} maxW={"422px"}>
        <Stack align={"center"} style={{ marginTop: "20px" }}>
          <img className="logo" alt="logo" src={Logo} />
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Text className="login-text">Sign Up</Text>

          <Stack spacing={1}>
          <CustomInput
              label="Full Name"
              type={"text"}
              placeholder={"Full Name"}
              name="fullname"
              onChange={(e) => setFullName(e.target.value)}
            />
            <CustomInput
              label="Username"
              type={"text"}
              placeholder={"Username"}
              name="username"
              onChange={(e) => setUserName(e.target.value)}
            />
             <CustomInput
              label="Referral Code (optional)"
              type={"text"}
              placeholder={"Refferal"}
              name="refferal"
              onChange={(e) => setRefferalCode(e.target.value)}
            />
             <CustomInput
              label="Email ID (optional)"
              type={"email"}
              placeholder={"Email"}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
             <CustomInput
              type={showPassword ? "text" : "password"}
              label="Password"
              placeholder={"Password"}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              icon={showPassword ? <FaEyeSlash onClick={() => setShowPassword(false)} /> : <FaEye onClick={() => setShowPassword(true)} />}
              iconPosition="right"
            />
            <CustomInput
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              placeholder={"Confirm Password"}
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              icon={showConfirmPassword ? <FaEyeSlash onClick={() => setShowConfirmPassword(false)} /> : <FaEye onClick={() => setShowConfirmPassword(true)} />}
              iconPosition="right"
            />
            <CustomInput
              label="Mobile"
              type={"text"}
              placeholder={"Mobile"}
              name="mobile"
              onChange={(e) => setMobile(e.target.value)}
            />
            <Box className="agree-box">
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <input
                  onChange={(e) => setAgeAgree(e.target.checked)}
                  type="checkbox"
                  id="agreeCheckbox"
                />
                <label htmlFor="agreeCheckbox">
                  <p
                    style={{
                      fontFamily: "'Inter',Helvetica",
                      fontSize: "11px",
                      fontWeight: 400,
                    }}
                  >
                    I am at least <span>18 years</span> of age and I have read,
                    accept and agree to the{" "}
                    <a href="www.google.com">Terms and Conditions</a>,{" "}
                    <a href>Responsible Gaming,</a>
                    <a href="www.google.com">GamCare,</a>{" "}
                    <a href="www.google.com">Gambling Therapy.</a>
                  </p>
                </label>
              </div>
            </Box>
            <br />
            <Stack spacing={10}>
              <Button
                onClick={signUpHandler}
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor={"#102a6d"}
                borderRadius={"10px"}
                height={"44px"}
                disabled={
                  !username ||
                  !password ||
                  !confirmPassword ||
                  !mobile ||
                  !ageCheck
                }
              >
                <Text
                  style={{
                    fontFamily: "'Inter',Helvetica",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  Sign Up
                </Text>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
