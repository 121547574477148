/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import LastResults from "../../../components/LastResults";
import Popover from "../../../components/Popover";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function DragonTigerLion() {
  const location = useLocation();
  const dispatch = useDispatch();
  const odds_ = useSelector((state) => state.gameData.dragonTigerLionOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const gameName = "DRAGON_TIGER_LION_20";
  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : "sm"}
      my="2"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="pattiButton"
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = useCallback(
    (id) => {
      let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
      return data ? data : {};
    },
    [odds?.oddDetailsDTOS]
  );

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    if (isLocked) return;
    if (!userData.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  useEffect(() => {
    dispatch(setGameKey("dragonTigerLion"));
  }, [dispatch]);
  console.log(userData, "userData");
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (const exposureData of plExposure) {
        for (const innerData of exposureData.casinoPLExposure) {
          if (innerData.selectionName == selectionName) {
            data = innerData;
          }
        }
      }

      return data ? data : null;
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box w={{ base: "100%", sm: "100%", lg: "70%" }} mr="1">
        <Box
          className="casino-video"
          overflow={"hidden"}
          h="60vh"
          mb="2"
          pos="relative"
        >
          <div className="market-title">20-20 D T L</div>
          {odds?.cards && (
            <CardDrawer
              labels={"DEALER"}
              cards={[odds?.cards[0], odds?.cards[1], odds?.cards[2]]}
            />
          )}

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3047`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className="rules-section">
                <ul className="pl-4 pr-4 list-style">
                  <li>
                    20-20 DTL(Dragon Tiger Lion) is a 52 playing cards game, In
                    DTL game 3 hands are dealt: for each 3 player. The player
                    will bets which will win.
                  </li>
                  <li>
                    The ranking of cards is, from lowest to highest: Ace, 2, 3,
                    4, 5, 6, 7,8, 9, 10, Jack, Queen and King when Ace is “1”
                    and King is “13”.
                  </li>
                  <li>
                    On same card with different suit, Winner will be declare
                    based on below winning suit sequence.
                    <p></p>
                    <div className="cards-box">
                      <span className="card-character black-card ml-1">
                        1{"}"}
                      </span>
                      <span>1st</span>
                    </div>
                    <p></p>
                    <p></p>
                    <div className="cards-box">
                      <span className="card-character red-card ml-1">
                        1{"{"}
                      </span>
                      <span>2nd</span>
                    </div>
                    <p></p>
                    <p></p>
                    <div className="cards-box">
                      <span className="card-character black-card ml-1">1</span>
                      <span>3rd</span>
                    </div>
                    <p></p>
                    <p></p>
                    <div className="cards-box">
                      <span className="card-character red-card ml-1">1</span>
                      <span>4th</span>
                    </div>
                    <p></p>
                  </li>
                </ul>
              </div>
            }
          />
        </Box>
       
        <Box className="row row5 d-t-l-row" style={{ width: "100%" }}>
          <div className="col-6">
            <div className="main-market">
              <table className="table coupon-table table table-bordered ">
                <thead>
                  <tr>
                    <th className="box-4"></th>
                    <th className="box-2">D</th>
                    <th className="box-2">T</th>
                    <th className="box-2">L</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <b>Winner</b>
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 100000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Winner D")}>
                        <span className="odd d-block">
                          {getData("Winner D").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Winner D")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Winner T")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Winner T").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Winner T")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Winner L")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Winner L").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Winner L")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <b>Black</b>
                      <span
                        className="card-icon"
                        style={{ marginBottom: "15px" }}
                      >
                        <span className="card-black">
                          <img
                            className="dtl-img"
                            src={require(`../../../assets/img/casino/spade.png`)}
                            alt=""
                          />
                        </span>
                      </span>
                      <span
                        className="card-icon"
                        style={{ marginBottom: "15px" }}
                      >
                        <span className="card-black">
                          <img
                            className="dtl-img"
                            src={require(`../../../assets/img/casino/club.png`)}
                            alt=""
                          />
                        </span>
                      </span>
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Black D")}>
                        <span className="odd d-block">
                          {getData("Black D").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Black D")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Black T")}>
                        <span className="odd d-block">
                          {getData("Black T").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Black T")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Black L")}>
                        <span className="odd d-block">
                          {getData("Black L").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Black L")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <b>Red</b>{" "}
                      <span
                        className="card-icon"
                        style={{ marginBottom: "15px" }}
                      >
                        <span className="card-red">
                          <img
                            className="dtl-img"
                            src={require(`../../../assets/img/casino/heart.png`)}
                            alt=""
                          />
                        </span>
                      </span>{" "}
                      <span
                        className="card-icon"
                        style={{ marginBottom: "15px" }}
                      >
                        <span className="card-red">
                          {" "}
                          <img
                            className="dtl-img"
                            src={require(`../../../assets/img/casino/diamond.png`)}
                            alt=""
                          />
                        </span>
                      </span>
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Red D")}>
                        <span className="odd d-block">
                          {getData("Red D").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Red D")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Red T")}>
                        <span className="odd d-block">
                          {getData("Red T").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Red T")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Red L")}>
                        <span className="odd d-block">
                          {getData("Red L").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Red L")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <b>Odd</b>
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Odd D")}>
                        <span className="odd d-block">
                          {getData("Odd D").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Odd D")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Odd T")}>
                        <span className="odd d-block">
                          {getData("Odd T").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Odd T")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Odd L")}>
                        <span className="odd d-block">
                          {getData("Odd L").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Odd L")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <b>Even</b>
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Even D")}>
                        <span className="odd d-block">
                          {getData("Even D").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Even D")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Even T")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Even T").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Even T")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Even L")}>
                        <span className="odd d-block">
                          {getData("Even L").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Even L")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/1 - Copy.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon A")}>
                        <span className="odd d-block">
                          {getData("Dragon A").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Dragon A")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger A")}>
                        <span className="odd d-block">
                          {getData("Tiger A").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger A")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion A")}>
                        <span className="odd d-block">
                          {getData("Lion A").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion A")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/2.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 2")}>
                        <span className="odd d-block">
                          {getData("Dragon 2").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon 2")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 2")}>
                        <span className="odd d-block">
                          {getData("Tiger 2").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger 2")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 2")}>
                        <span className="odd d-block">
                          {getData("Lion 2").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion 2")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/3.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 3")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Dragon 3").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon 3")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 3")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Tiger 3").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger 3")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 3")}>
                        <span className="odd d-block">
                          {getData("Lion 3").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Lion 3")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/4.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 4")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Dragon 4").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Dragon 4")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 4")}>
                        <span className="odd d-block">
                          {getData("Tiger 4").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger 4")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 4")}>
                        <span className="odd d-block">
                          {getData("Lion 4").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Lion 4")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            <div className="main-market">
              <table className="table coupon-table table table-bordered ">
                <thead>
                  <tr>
                    <th className="box-4"></th>
                    <th className="box-2">D</th>
                    <th className="box-2">T</th>
                    <th className="box-2">L</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/5.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 5")}>
                        <span className="odd d-block">
                          {getData("Dragon 5").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon 5")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 5")}>
                        <span className="odd d-block">
                          {getData("Tiger 5").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Tiger 5")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 5")}>
                        <span className="odd d-block">
                          {getData("Lion 5").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion 5")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/6.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 6")}>
                        <span className="odd d-block">
                          {getData("Dragon 6").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon 6")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 6")}>
                        <span className="odd d-block">
                          {getData("Tiger 6").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Tiger 6")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 6")}>
                        <span className="odd d-block">
                          {getData("Lion 6").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Lion 6")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/7.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 7")}>
                        <span className="odd d-block">
                          {getData("Dragon 7").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon 7")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 7")}>
                        <span className="odd d-block">
                          {getData("Tiger 7").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger 7")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 7")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Lion 7").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion 7")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/8.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 8")}>
                        <span className="odd d-block">
                          {getData("Dragon 8").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Dragon 8")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 8")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Tiger 8").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Tiger 8")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 8")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Lion 8").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion 8")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/9.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 9")}>
                        <span className="odd d-block">
                          {getData("Dragon 9").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon 9")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 9")}>
                        <span className="odd d-block">
                          {getData("Tiger 9").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger 9")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 9")}>
                        <span className="odd d-block">
                          {getData("Lion 9").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion 9")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/10.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon 10")}>
                        <span className="odd d-block">
                          {getData("Dragon 10").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Dragon 10")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger 10")}>
                        <span className="odd d-block">
                          {getData("Tiger 10").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger 10")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion 10")}>
                        <span className="odd d-block">
                          {getData("Lion 10").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion 10")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/11.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon J")}>
                        <span className="odd d-block">
                          {getData("Dragon J").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon J")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger J")}>
                        <span className="odd d-block">
                          {getData("Tiger J").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger J")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion J")}>
                        <span className="odd d-block">
                          {" "}
                          {getData("Lion J").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion J")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/12.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon Q")}>
                        <span className="odd d-block">
                          {getData("Dragon Q").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon Q")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger Q")}>
                        <span className="odd d-block">
                          {getData("Tiger Q").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Tiger Q")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion Q")}>
                        <span className="odd d-block">
                          {getData("Lion Q").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Lion Q")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-4 card-type-icon">
                      <img
                        className="dtl-img"
                        src={require(`../../../assets/img/casino/13.png`)}
                        alt=""
                      />
                      <div className="info-block float-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p> <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Dragon K")}>
                        <span className="odd d-block">
                          {getData("Dragon K").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Dragon K")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Tiger K")}>
                        <span className="odd d-block">
                          {getData("Tiger K").back1}
                        </span>{" "}
                        <span className="d-block">
                          {" "}
                          {getExposureForEntity("Tiger K")?.exposure}
                        </span>
                      </button>
                    </td>
                    <td
                      className={`box-2 back ${isLocked ? "suspendedtd" : ""}`}
                    >
                      <button onClick={() => setEvent("Lion K")}>
                        <span className="odd d-block">
                          {getData("Lion K").back1}
                        </span>{" "}
                        <span className="d-block">
                          {getExposureForEntity("Lion K")?.exposure}
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Box>
     
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
