/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  Box,
  useDisclosure,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
  useToast,
  ModalCloseButton,
  ModalBody,
  Button,
  usePrefersReducedMotion,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Image,
  Flex,
} from '@chakra-ui/react';
import Header from '../includes/header';
import { getUserDataAPI } from '../service/UserService';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../components/CustomInput';
import {
  changeButtonValuesAPI,
  changePasswordAPI,
  dashboardBannerAPI,
  getAnnouncementAPI,
  getButtonValuesAPI,
  getExposureTableAPI,
  getSignedUrlAPI,
  welcomeBannerAPI,
} from '../service/AuthService';
import { buttonColors, Encrypt } from '../utils/constants';
import CustomTable from '../components/Table';
import Sidebar from '../includes/sidebar/Sidebar';
import { useState } from 'react';
import MobileHeader from '../includes/header/MobileHeader';
import { FooterMui } from '../components/Footer';
import { CardItem } from '../components/CardItems';
import BannerSlider from '../pages/banner';

const PrivateOutlet = (props) => {
  const location = useLocation();
  const userData = useSelector((state) => state.account.userData);
  const selectedEventForBet = useSelector(
    (state) => state?.gameData?.selectedEventForBet
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleSliderChange = (value) => {
    setCurrentIndex(value);
  };

  const [buttons, setButtons] = React.useState({});
  const [announcement, setAnnouncement] = React.useState({});
  const [homeImages, setHomeImages] = useState([]);
  var newHomeImages = [];

  const isHomePage = location.pathname === '/' || location.pathname === '/home';

  const getBannerImages = async () => {
    var res = await welcomeBannerAPI();
    console.log('banner data', res);
    if (res.status === 200) {
      let data = res.data.response;

      for (let i = 0; i < data.length; i++) {
        let res = await getSignedUrlAPI(data[i].bannerUrl);
        //array store saariimages ye images mai box welcome banner
        console.log('SignedUrl', res);
        if (res?.data?.code === 200) {
          newHomeImages.push(res.data?.response);
          data[i].bannerUrl = res.data?.response;
        }
      }
      //setHomeImages(data.map((item) => item.bannerUrl));
      setHomeImages((prevHomeImages) => [...prevHomeImages, ...newHomeImages]);
      //setImages(data.map((item) => item.bannerUrl));
    }
  };
  useEffect(() => {
    getBannerImages();
  }, []);
  useEffect(() => {
    console.log('homeImagesCloud', homeImages);
  }, [homeImages]);

  const getAnnouncement = async () => {
    const res = await getAnnouncementAPI();
    if (res.code === 200) {
      setAnnouncement(res.response);
    }
  };
  const handleUpdate = (key, value) => {
    setButtons((prev) => ({ ...prev, [key]: value }));
  };
  const getButtonValues = async () => {
    const res = await getButtonValuesAPI();
    if (res.code === 200) {
      setButtons(res.response);
    }
  };
  const handleSave = async () => {
    const res = await changeButtonValuesAPI(buttons);

    if (res.code === 200) {
      toast({
        title: 'Success',
        description: 'Button values updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
    getButtonValues();
  };

  useEffect(() => {
    getButtonValues();
  }, []);
  const dispatch = useDispatch();
  const toast = useToast();
  const isChangePasswordPopOpen = useSelector(
    (state) => state.account.ui.changePasswordPopOpen
  );
  const isChangeButtonPopOpen = useSelector(
    (state) => state.account.ui.changeButtonPopOpen
  );
  const isMyMarketPopOpen = useSelector(
    (state) => state.account.ui.myMarketPopOpen
  );
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [exposureTable, setExposureTable] = React.useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(false);

  const handleCloseWelcomeOpen = () => {
    localStorage.setItem('welcome', 'closed');
    setWelcomeOpen(false);
  };
  const handleOpenWelcomeOpen = () => setWelcomeOpen(true);

  const getWelcomeBanner = async () => {
    const res = await welcomeBannerAPI();
    console.log('announcement', res);
    if (res?.status === 200) {
    }
  };

  const changePassword = async () => {
    if (newPassword === '' || confirmPassword === '' || oldPassword === '') {
      toast({
        description: 'Please fill all the fields',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        description: 'newPassword and confirm Password does not match',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (newPassword === oldPassword) {
      toast({
        description: 'New Password and old Password cannot be same',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        description:
          'newPassword must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    var res = await changePasswordAPI(
      {
        newPassword: Encrypt(newPassword),
        password: Encrypt(oldPassword),
        resetRequired: false,
      },
      userData.token
    );
    console.log(res, 'resData');
    if (res.data.code === 200) {
      toast({
        title: 'Success',
        description: 'New Password changed successfully',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      dispatch({
        type: 'accountData/logOut',
        payload: {},
      });
      dispatch({
        type: 'gameData/logOut',
        payload: {},
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.href = '/';
      }, 500);
    } else {
      toast({
        description: 'Wrong Password',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    }
  };
  const getExposureTable = async () => {
    var res = await getExposureTableAPI();
    if (res.code === 200) {
      setExposureTable(res.response);
    }
  };

  useEffect(() => {
    getWelcomeBanner();
    if (userData.token && userData.token != null) {
      getExposureTable();
      let interval = setInterval(() => {
        getUserData();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const getUserData = async () => {
    var res = await getUserDataAPI(userData.id);

    if (res && res?.code === 200) {
      dispatch({
        type: 'accountData/setUserProfile',
        payload: res.response,
      });
    } else {
      dispatch({
        type: 'accountData/logOut',
        payload: {},
      });
      dispatch({
        type: 'gameData/logOut',
        payload: {},
      });
    }
  };
  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSidebarOpen]);

  if (!userData.token) {
    return (window.location.href = '/');
  }

  useEffect(() => {
    const welcomeClosed = localStorage.getItem('welcome');
    if (!welcomeClosed && !userData?.resetRequired) {
      handleOpenWelcomeOpen();
    }
  }, [userData?.resetRequired]);

  console.log(oldPassword, newPassword, 'password', confirmPassword);

  return (
    <>
      <Box display={{ base: 'block', md: 'none' }}>
        <MobileHeader />
      </Box>
      <Box display={{ base: 'none', md: 'block' }}>
        <Header setIsSidebarOpen={setIsSidebarOpen} />
      </Box>
      {/* <Box className="banner-box"></Box> */}

      {isHomePage && <BannerSlider />}

      <div className='container-fluid container-fluid-5 snipcss-fJ9mV'>
        <div className='row row5 m-0'>
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <Box className='content-box-main'>
            <Flex className='game-categories' direction={'row'}>
              {/* <Box className="game-card cricket">
                <CricketIcon /> <p>CRICKET</p>
              </Box>
              <Box className="game-card football">
                <FootballIcon /> <p>FOOTBALL</p>
              </Box>
              <Box className="game-card tennis">
                <TennisIcon /> <p>TENNIS</p>
              </Box>
              <Box className="game-card casino">
                <CasinoIcon /> <p>CASINO</p>
              </Box>
              <Box className="game-card card-game">
                <CardGameIcon /> <p>CARD GAME</p>
              </Box> */}
              <CardItem />
            </Flex>

            <Box className='private-body'>{props.children}</Box>
          </Box>
        </div>
      </div>
      <FooterMui />

      <Modal
        size={'lg'}
        onClose={() => {
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          if (userData?.token && userData?.resetRequired) {
            dispatch({
              type: 'accountData/logOut',
              payload: {},
            });
            dispatch({
              type: 'gameData/logOut',
              payload: {},
            });
            setTimeout(() => {
              window.location.reload();
              window.localStorage.clear();
              window.location.href = '/';
            }, 500);
          }
          dispatch({
            type: 'accountData/setChangePopSate',
            payload: false,
          });
        }}
        isOpen={isChangePasswordPopOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent zIndex={10000} marginTop={10} bg={'rgb(51,51,51)'}>
          <ModalHeader bg={'#fff'} p='1'>
            <Text fontSize='sm' color={'#fff'}>
              Change Password
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2' bg={'#fff'}>
            <CustomInput
              label='Old Password'
              type='password'
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              bg={'#ddd'}
            />
            <CustomInput
              label='New Password'
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              bg={'#ddd'}
            />
            <CustomInput
              label='Confirm Password'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              bg={'#ddd'}
            />
            <Button
              onClick={changePassword}
              mt='10px'
              w={'100%'}
              bg={buttonColors}
            >
              Change Password
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size={'lg'}
        onClose={() =>
          dispatch({
            type: 'accountData/setChangeButtonState',
            payload: false,
          })
        }
        isOpen={isChangeButtonPopOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent marginTop={10} bg={'#494949'}>
          <ModalHeader bg={'#383838'} p='1' borderRadius={'5px'}>
            <Text fontSize='sm' color={'#FDCF13'}>
              Set Button Value
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2' bg={'#494949'}>
            <Button onClick={handleSave} mt='10px' w={'100%'} bg={buttonColors}>
              Submit
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size={'4xl'}
        onClose={() =>
          dispatch({
            type: 'accountData/setMyMarketPopState',
            payload: false,
          })
        }
        isOpen={isMyMarketPopOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent marginTop={10} bg={'rgb(51,51,51)'}>
          <ModalHeader bg={'#3C444B'} p='1' borderRadius={'5px'}>
            <Text fontSize='sm' color={'#FDCF13'}>
              My Market
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2' bg={'#2E3439'} borderRadius={'5px'}>
            <CustomTable
              rows={exposureTable}
              rowsPerPage_={20}
              showPagination={false}
              totalPages_={1}
              currentPage_={0}
              columns={[
                {
                  name: 'Event Type',
                  id: 'eventType',
                },
                {
                  name: 'Event Name',
                  id: 'eventName',
                },
                {
                  name: 'Match Name',
                  id: 'marketName',
                },
                {
                  name: 'Trade',
                  id: 'trade',
                },
              ]}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={welcomeOpen} onClose={handleCloseWelcomeOpen} size='lg'>
        <ModalOverlay
          style={{
            backdropFilter: 'blur(4px)', // Add blur effect to the overlay
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust the background color and opacity
          }}
        />
        <ModalContent
          // boxShadow="none"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ModalBody>
            <Box className='welcome-banner-container'>
              <Image
                className='welcome-banner-image'
                src={homeImages[currentIndex]}
                alt={`Image ${currentIndex}`}
              />
            </Box>
          </ModalBody>
          <Button
            onClick={handleCloseWelcomeOpen}
            bg='black !important'
            color='white'
            mt={4}
            p={2}
            borderRadius='md'
            border='2px solid white !important'
            _hover={{ bg: 'gray.700 !important' }}
          >
            Close
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PrivateOutlet;
