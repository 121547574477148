import React from 'react';

const TennisMenuIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='22'
      viewBox='0 0 20 22'
      fill='none'
    >
      <path
        d='M19.9999 12.0518C19.9477 12.4581 19.9154 12.8678 19.8408 13.2691C19.1577 16.9422 17.2238 19.5714 14.0673 21.1732C14.0438 21.1849 14.0193 21.1939 13.9948 21.2034C13.9776 21.2089 13.9705 21.1789 13.9834 21.1663C14.0576 21.0937 14.1261 21.0287 14.1904 20.9603C15.9881 19.0493 16.5741 16.7774 15.9584 14.1482C15.5404 12.3625 14.9215 10.6478 14.3605 8.91179C14.1826 8.3607 14.0099 7.80011 13.9102 7.2289C13.6243 5.59688 14.6914 3.9928 16.2135 3.68261C16.6566 3.59206 16.9785 3.66975 17.3047 4.05764C18.866 5.91322 19.775 8.06056 19.967 10.5533C19.9754 10.6623 19.9999 10.7694 19.9999 10.8787C19.9999 11.2692 19.9999 11.6607 19.9999 12.0518Z'
        fill='#FF8D1B'
      />
      <path
        d='M5.1415 2.81067C4.84611 2.6456 4.79736 2.24687 5.0838 2.06671C7.80013 0.358187 11.4174 0.220755 14.2513 1.68035C14.8741 2.00112 14.7969 2.7884 14.2489 3.22482C13.0195 4.20236 12.2232 6.01435 12.7988 8.01694C13.1677 9.29964 13.617 10.5561 13.9995 11.8349C14.3105 12.8744 14.6298 13.9174 14.8516 14.981C15.4611 17.9002 13.5152 20.8915 10.7548 21.3448C8.0966 21.7813 5.59288 19.8525 5.10967 17.0104C4.93695 15.9948 5.04549 15.0084 5.33875 14.0426C5.83292 12.415 6.36309 10.7992 6.851 9.16941C7.05295 8.49592 7.26011 7.80791 7.33473 7.11038C7.52311 5.34478 6.62087 3.63674 5.1415 2.81067Z'
        fill='#FF8D1B'
      />
      <path
        d='M5.95612 21.1745C5.95284 21.1836 5.94218 21.1879 5.93368 21.1833C5.65807 21.0331 5.37589 20.8955 5.10976 20.7288C2.44272 19.0621 0.737917 16.6208 0.184783 13.3651C-0.439841 9.68527 0.527622 6.47879 2.95776 3.78427C3.06108 3.66969 3.16597 3.62722 3.31573 3.63281C4.85616 3.69093 6.1315 5.05356 6.1148 6.70403C6.10958 7.20817 5.98069 7.72516 5.83301 8.20974C5.38842 9.67129 4.88799 11.1133 4.44757 12.5765C4.21066 13.3624 3.98993 14.1649 3.87095 14.9787C3.54429 17.2116 4.16421 19.1454 5.61645 20.778C5.72916 20.9048 5.84087 21.0321 5.95305 21.1588C5.95689 21.1632 5.95809 21.1691 5.95612 21.1745Z'
        fill='#FF8D1B'
      />
    </svg>
  );
};

export default TennisMenuIcon;
