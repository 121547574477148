import React from "react";
import SignUpCard from "../../components/login/signUp";

const SignUp = () => {
  return (
    <>
      <div className="login">
        <SignUpCard />
      </div>
    </>
  );
};

export default SignUp;
