import React, { useCallback, useEffect, useState } from 'react';
// import '../MyBets/bets.css';

// import Footerbet from '../MyBets/Footerbet';
// import LoginHeader from '../LoginDashboard/LoginHeader';
// import { AnnouncementTimeline } from '../LoginDashboard/AnnouncementTimeline';
// import SportMenu from '../LoginDashboard/SportsMenu';
// import LeftPaneSidebar from '../LeftPaneSidebarMenu/LeftPaneSidebar';
import axios from 'axios';
import { BASE_URL } from '../../../utils/constants';
import { AXIOS_INSTANCE } from '../../../service';
import { Pagination } from '@mui/material';
const MywithdrawlSec = () => {
  const [depositData, setDepositData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getDepositData = useCallback(async () => {
    const payload = {
      transactionTypes: ['WITHDRAW_FROM_WALLET'],
      userId: localStorage.getItem('userId'),
    };
    const res = await AXIOS_INSTANCE.post(
      `${BASE_URL}/api/admin/user/transactions?pageNumber=${
        currentPage - 1
      }&size=${pageSize}`,
      payload
    );
    console.log(res, 'res');
    if (res.status === 200) {
      setDepositData(res.data.data.data.content);
      setTotalCount(res.data.data.data.totalElements);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    getDepositData();
  }, [getDepositData]);

  return (
    <>
      {/* <LoginHeader></LoginHeader>
      <AnnouncementTimeline></AnnouncementTimeline>
      <SportMenu></SportMenu> */}
      <section class='content_layout-wrapper content_layout-left_mid'>
        {/* <LeftPaneSidebar /> */}
        <div class='mid-pane' style={{ marginLeft: '5px' }}>
          <div class='order-listing my-bets-wrapper'>
            <div class='events w-100'>
              <h1 class='d-flex'>
                <span class='back-button-h1'>
                  <a href='/' class='btn back-btn-round' tabindex='26'>
                    <i class='fa fa-home' aria-hidden='true'></i>
                  </a>
                </span>{' '}
                My Withdraw{' '}
              </h1>
              {/* <div class='act-datepicker-selection'>
                <form action=''>
                  <div class='apl-report-filter'>
                    <div class='datepicker p-0'>
                      <label for=''>From</label>
                      <input
                        type='text'
                        name='from'
                        value='08/10/2023'
                        data-provide='datepicker'
                        class='form-control'
                        placeholder='08/10/2023'
                        data-date-format='dd/mm/yyyy'
                      />
                    </div>
                    <div class='datepicker'>
                      <label for=''>To</label>
                      <input
                        type='text'
                        name='to'
                        value='08/10/2023'
                        data-provide='datepicker'
                        class='form-control'
                        placeholder='08/10/2023'
                        data-date-format='dd/mm/yyyy'
                      />
                    </div>
                    <div class='button-holder'>
                      <button
                        type='submit'
                        class='btn green-button'
                        style={{ marginLeft: '0', marginRight: '0 !important' }}
                      >
                        <i class='fa fa-search' aria-hidden='true'></i>Search{' '}
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className='table-responsive text-end'>
              <table
                className='table table01'
                style={{ width: '1495px', textAlign: 'justify' }}
              >
                <thead style={{ paddingTop: '15px' }}>
                  <tr>
                    <th className='text-start'>TransactionId</th>
                    <th scope='col'>UserId</th>
                    <th scope='col'>Coins</th>
                    <th scope='col'>Approved Coins</th>
                    <th scope='col'>Transaction Status</th>
                    <th scope='col'>Transaction Type</th>
                    <th>Generated At</th>
                    <th>Validated At</th>

                    {/* Add more column headings as needed */}
                  </tr>
                </thead>
                <tbody>
                  {depositData.map((ele, index) => (
                    <tr key={index}>
                      <td style={{ paddingLeft: '38px' }}>
                        {ele.transactionId}
                      </td>
                      <td>{ele.toUserId}</td>
                      <td>{ele.coins}</td>
                      <td>{ele.approvedCoins}</td>
                      <td style={{ paddingLeft: '25px' }}>
                        {ele.transactionStatus}
                      </td>
                      <td>{ele.transactionType}</td>
                      <td>{ele.requestGenerated.split('T')[0]}</td>
                      <td>
                        {ele?.requestValidated?.length > 0
                          ? ele.requestValidated.split('T')[0]
                          : 'Not Validated Yet'}
                      </td>
                      <td>
                        {/* <button onClick={() => handleShow(ele)}>
                        Show ScreenShot
                      </button> */}
                      </td>
                      {/* Add more columns as needed */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </section>
      {/* <Footerbet /> */}
    </>
  );
};

export default MywithdrawlSec;
