import { Box, Grid, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './games/css/style.scss';
import './games/css/app.scss';
import { ASSETS_URL, createSocket } from '../../utils/constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export default function CasinoList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [casinoList, setCasinoList] = React.useState([
    // {
    //   name: 'Dragon Tiger-1 2020',
    //   image: 'dt20.jpg',
    //   link: '/live-casino/dragon-tiger/20-20',
    //   group: 'dragon-tiger',
    // },
    // {
    //   name: 'Dragon Tiger 2020 2',
    //   image: 'dt202.jpg',
    //   link: '/live-casino/dragon-tiger/20-20/2',
    //   group: 'dragon-tiger',
    // },
    // {
    //   name: 'Dragon Tiger 1 Day',
    //   image: 'dt6.jpg',
    //   link: '/live-casino/dragon-tiger/1-day',
    //   group: 'dragon-tiger',
    // },
    // {
    //   name: 'Dragon Tiger Lion',
    //   image: 'dtl20.jpg',
    //   link: '/live-casino/dragon-tiger/dtl',
    //   group: 'dragon-tiger',
    // },
    // {
    //   name: 'Teen Patti 2020',
    //   image: 'teen20.jpg',
    //   link: '/live-casino/teenpatti/20-20',
    //   group: 'teenpatti',
    // },

    // {
    //   name: 'Teen Patti Test',
    //   image: 'teen9.jpg',
    //   link: '/live-casino/teenpatti/test',
    //   group: 'teenpatti',
    // },
    // {
    //   name: 'Teen Patti Open',
    //   image: 'teen8.jpg',
    //   link: '/live-casino/teenpatti/open',
    //   group: 'teenpatti',
    // },
    // {
    //   name: 'Casino Queen',
    //   image: 'queen.jpg',
    //   link: '/live-casino/cn/queen',
    //   group: 'casino',
    // },
    // {
    //   name: '32 Cards A',
    //   image: 'card32.jpg',
    //   link: '/live-casino/card-32/a',
    //   group: '32-cards',
    // },
    // {
    //   name: '32 Cards B',
    //   image: 'card32eu.jpg',
    //   link: '/live-casino/card-32/b',
    //   group: '32-cards',
    // },

    // {
    //   name: 'Poker 20-20',
    //   image: 'poker20 20.jpg',
    //   link: '/live-casino/poker/20-20',
    //   group: 'poker',
    // },
    {
      name: 'Lucky 7-A',
      image: 'lucky7.jpg',
      link: '/live-casino/lucky7/a',
      group: 'lucky',
    },
    {
      name: 'Lucky 7-B',
      image: 'lucky7eu.jpg',
      link: '/live-casino/lucky7/b',
      group: 'lucky',
    },
    // {
    //   name: 'Andar Bahar',
    //   image: 'andar-bahar.jpg',
    //   link: '/live-casino/andar-bahar',
    //   group: 'andar-bahar',
    // },
    // {
    //   name: 'Andar Bahar 2',
    //   image: 'andar-bahar2.jpg',
    //   link: '/live-casino/andar-bahar/2',
    //   group: 'andar-bahar',
    // },

    {
      name: 'Bollywood Casino',
      image: 'btable.jpg',
      link: '/live-casino/cn/bollywood-table',
      group: 'bollywood',
    },
    {
      name: 'Amar Akbar Anthony',
      image: 'aaa.jpg',
      link: '/live-casino/andar-bahar/aaa',
      group: 'bollywood',
    },
    {
      name: 'Instant Worli',
      image: 'instant worli.jpg',
      link: '/live-casino/worli/2',
      group: 'wroli',
    },

    {
      name: 'Casino War',
      image: 'casino-war.jpg',
      link: '/live-casino/cn/war',
      group: 'casino',
    },
    {
      name: 'Race 20',
      image: 'race-20.jpg',
      link: '/live-casino/race/20-20',
      group: 'race',
    },
    {
      name: '5-5 Cricket',
      image: 'five cricket.jpg',
      link: '/live-casino/cricket/five',
      group: 'sports-casino',
    },
    {
      name: 'Cricket 20-20',
      image: 'cmatch20.jpg',
      link: '/live-casino/cricket/20-20',
      group: 'sports-casino',
    },
    // {
    //   name: 'Baccart',
    //   image: 'baccarat.jpg',
    //   link: '/live-casino/baccarat',
    //   group: 'baccarat',
    // },
    // {
    //   name: 'Baccart 2',
    //   image: 'baccarat2.jpg',
    //   link: '/live-casino/baccarat/2',
    //   group: 'baccarat',
    // },
    // {
    //   name: "Worli Matka",
    //   image: "worli.jpg",
    //   link: "/live-casino/worli",
    // },
    {
      name: 'Super Over',
      image: 'superover.jpg',
      link: '/live-casino/cricket/super-over',
      group: 'sports-casino',
    },
    // {
    //   name: "1-Day Poker",
    //   image: "poker 1 day.jpg",
    //   link: "/live-casino/poker",
    // },
    // {
    //   name: '1 Day Teen Patti',
    //   image: 'teen.jpg',
    //   link: '/live-casino/teenpatti/1-day',
    //   group: 'teenpatti',
    // },
    // {
    //   name: 'Poker 6 Players',
    //   image: 'poker6.jpg',
    //   link: '/live-casino/poker/poker-6-player',
    //   group: 'poker',
    // },
    // {
    //   name: "3 Cards Judgement",
    //   image: "3 card judgement.jpg",
    //   link: "/live-casino/3cardsjudgement",
    // },
    {
      name: 'Casino Meter',
      image: 'casino meter.jpg',
      link: '/live-casino/cn/meter',
      group: 'sports-casino',
    },
  ]);

  useEffect(() => {
    if (searchParams.get('name')) {
      const tempList = [...casinoList];
      setCasinoList(
        tempList.filter((j) => j.group === searchParams.get('name'))
      );
    }
  }, []);

  return (
    <Grid
      mt={{ base: '1px !important', md: '0px' }}
      gap={2}
      templateColumns='repeat(3,1fr)'
      className='casino-live-page'
    >
      {casinoList.map((casino, index) => (
        <GridItem
          onClick={() => {
            window.location.href = casino.link;
          }}
          cursor='pointer'
          position='relative'
        >
          <Image
            h='200'
            width={'100%'}
            src={ASSETS_URL + casino.image}
            className='casino-game-img'
          />
        </GridItem>
      ))}
    </Grid>
  );
}
