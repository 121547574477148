import React, { useState } from "react";
import { Stack, Typography, Link, Divider, Box } from "@mui/material";
// import { Modal } from "@chakra-ui/react";
import balance from "../../assets/images/icons/balance.png";
import key from "../../assets/images/icons/key.png";
import "../../assets/styles/css/userDashBoard.scss";
import ResetPassword from "../../components/ResetPasswordForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import value from "../../assets/images/icons/value.png";
import PriceLabelForm from "../../components/PriceLabelForm";
import { Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ChangeButtonValue from "../ChangeButtonValue";
import ChangePassword from "../ChangePassword";
import { CopyIcon } from "@chakra-ui/icons";

export const UserDashBoardPage = () => {
  const toast = useToast();
  const userData = useSelector((state) => state.account.userData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const navigate = useNavigate();
  const referralCode = localStorage.getItem("refCode");

  const handleViewDetails = () => {
    navigate("/user-details");
  };

  const handleOpenModal = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
  };

  const handleOpenModal1 = () => {
    console.log("Opening modal");
    setIsModalOpen1(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralCode);
    toast({
      title: "Success",
      description: "Referral code copied to clipboard",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Stack className="user-main-stack">
      {/* User DashBoard heading */}
      <Stack className="dashboard__heading">
        <Typography className="text-1">User Dashboard</Typography>

        <Divider className="divider-style"></Divider>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Text className="text-center">Referral Code: {referralCode}</Text>
          <IconButton
            onClick={handleCopyClick}
            size="sm"
            ml={2}
            style={{ color: "black", backgroundColor: "transparent" }}
          >
            <CopyIcon />
          </IconButton>
        </Stack>
        <Divider className="divider-style"></Divider>

        {/* User balance Information */}
        <Stack className="user-info" gap={50}>
          <Stack className="user-info-stack-1">
            <img src={balance} alt="balance-img" className="balance-img" />
            <Typography className="balance-text">
              Balance {userData.balance}
            </Typography>
            {/* <Typography className='balance-text'>{userData.balance}</Typography> */}
            <Link className="link-1" onClick={handleViewDetails}>
              View Details
            </Link>
          </Stack>

          {/* User change password */}
          <Stack className="user-info-stack-2">
            <img src={key} alt="password" className="pass-icon" />
            <Typography className="password-text">Change Password</Typography>
            {/* Use ResetModal component here */}
            <Text className="link-2" onClick={handleOpenModal}>
              Change password
            </Text>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalBody>
                  <Box
                    className="modal-box-reset"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "33.54vw",
                      transform: "translate(-50%, -50%)",
                      height: "75.2vh",
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 96px rgba(0, 0, 0, 0.1)",
                      padding: "8px",
                      overflow: "auto",
                    }}
                  >
                    <ModalCloseButton pos="absolute" right="15px" top="10px" />
                    <ChangePassword onClick={handleCloseModal} />
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Stack>

          {/* For game button value */}
          <Stack className="user-info-stack-2">
            <img src={value} alt="password" className="pass-icon" />
            <Typography className="password-text">Button Value</Typography>
            {/* Use GameButtonModal component here */}
            <Link className="link-2" onClick={handleOpenModal1}>
              Button Value
            </Link>
            <Modal
              isOpen={isModalOpen1}
              onClose={handleCloseModal1}
              className="body"
            >
              <ModalContent className="content">
                <ModalBody className="body">
                  <Box
                    className="price-modal"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "40vw",
                      transform: "translate(-50%, -50%)",
                      height: "88.2vh",
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 96px rgba(0, 0, 0, 0.1)",
                      padding: "8px",
                      overflow: "auto",
                    }}
                  >
                    <ChangeButtonValue onClick={handleCloseModal1} />
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
