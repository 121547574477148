import React, { useState } from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import { Grid, useToast } from "@chakra-ui/react";
import CustomInput from "../../components/CustomInput";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { registerAPI } from "../../service/AuthService";
import { Encrypt } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import "../../components/fixture.css";

export const AddNewUser = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ageAgree, setAgeAgree] = useState(false);

  const referralCode = localStorage.getItem("refCode");

  const signUpHandler = async () => {
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  
    if (!mobile) {
      return toast({
        title: "Warning",
        description: "Mobile number is required!",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
  
    if (!ageAgree) {
      return toast({
        title: "Warning",
        description: "Minimum age 18 is required!",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
  
    try {
      const res = await registerAPI({
        mobile,
        username: userName,
        refferalCode: referralCode,
        password: Encrypt(password),
        transactionPassword: "172586",
      });
  
      if (res.code === 200) {
        navigate("/");
        toast({
          title: "Success",
          description: "Success",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const errorMessage = res?.error?.description || res?.description || "Something went wrong";
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("API call failed:", error);
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  

  return (
    <>
      <Stack className="main" gap={5}>
        <Stack className="main-1">
          <Typography className="send-text">Add New User</Typography>
        </Stack>

        <Stack spacing={4}>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={5}
          >
            <CustomInput
              label="Full Name"
              type="text"
              placeholder="Full Name"
              name="fullname"
              onChange={(e) => setFullName(e.target.value)}
            />
            <CustomInput
              label="Username"
              type="text"
              placeholder="Username"
              name="username"
              onChange={(e) => setUserName(e.target.value)}
            />
            <CustomInput
              label="Email ID (optional)"
              type="email"
              placeholder="Email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <CustomInput
              type={showPassword ? "text" : "password"}
              label="Password"
              placeholder="Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              rightIcon={
                showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(false)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(true)} />
                )
              }
            />
            <CustomInput
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              rightIcon={
                showConfirmPassword ? (
                  <FaEyeSlash onClick={() => setShowConfirmPassword(false)} />
                ) : (
                  <FaEye onClick={() => setShowConfirmPassword(true)} />
                )
              }
            />
            <CustomInput
              label="Mobile"
              type="text"
              placeholder="Mobile"
              name="mobile"
              onChange={(e) => setMobile(e.target.value)}
            />
          </Grid>
        </Stack>
        <Box className="agree-box" style={{ maxWidth: "200px" }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              onChange={(e) => setAgeAgree(e.target.checked)}
              type="checkbox"
              id="agreeCheckbox"
            />
            <label htmlFor="agreeCheckbox">
              <p
                style={{
                  fontFamily: "'Inter',Helvetica",
                  fontSize: "11px",
                  fontWeight: 400,
                }}
              >
                I am at least <span>18 years</span> of age and I have read,
                accept and agree to the{" "}
                <a href="www.google.com">Terms and Conditions</a>,{" "}
                <a href="#">Responsible Gaming,</a>
                <a href="www.google.com">GamCare,</a>{" "}
                <a href="www.google.com">Gambling Therapy.</a>
              </p>
            </label>
          </div>
        </Box>
        <Stack className="main-6">
          <Button
            className="btn"
            variant="contained"
            onClick={signUpHandler}
            disabled={
              !userName || !password || !confirmPassword || !mobile || !ageAgree
            }
          >
            <Typography className="btn-text">Submit</Typography>
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
