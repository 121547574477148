import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { GenericTable } from "../shared/GenericTable";
import { SearchWithText } from "../shared/SearchWithText";
import BasicDatePicker from "../shared/DatePicker";
import { SelectType } from "../shared/SelectType";
import "../../assets/styles/css/account.scss";
import { getAccountStatementAPI } from "../../service/UserService";
import { formatDate } from "./index.utils";
import moment from "moment";
import { options } from "../shared/SelectType/index.utils";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react";
import { getBetsFromAccountLogs } from "../../service/AuthService";

export const AccountStatement = () => {
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "days").toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [type, setType] = React.useState("ALL");


  const [logData, setLogData] = React.useState({});
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedType, setSelectedType] = useState("Select Type");

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setSelectedType(event.target.value);
  };

  const handleFromDateChange = (date, isFromDate) => {
    const _from = new Date(date);
    if (isFromDate) {
      _from.setUTCHours(23, 59, 59, 999);
      setFromDate(_from);
    } else {
      _from.setUTCHours(0, 0, 0, 0);
      setToDate(_from);
    }
  };
  const handleToDateChange = (date, isFromDate) => {
    const _to = new Date(date);
    if (isFromDate) {
      _to.setUTCHours(0, 0, 0, 0);
      setFromDate(_to.toISOString());
    } else {
      _to.setHours(23, 59, 59, 999);
      setToDate(_to.toISOString());
    }
  };

  const getAccountStatementData = async () => {
    try {
      let payload = {
        pageNumber: 0,
        pageSize: 100,
        startDate: new Date(fromDate),
        endDate: new Date(toDate),
        type: type,
      };
      const res = await getAccountStatementAPI(payload);
      if (res && res.code === 200) {
        const sortedData = res.response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setTableData(sortedData);
        setFilteredTableData(sortedData);
        console.log(res, "Data");
      } else {
        console.log("invalid res:", res);
      }
    } catch (error) {
      console.error("Error fetching cricket list:", error);
    }
  };

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();

    const filteredData = tableData.filter((data) => {
      const srNoMatch = String(data.srNo)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const creditMatch = String(data.creditInfo)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const debitMatch = String(data.debitInfo)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const balanceMatch = String(data.currentBalance)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const fromTo = String(data.fromTo)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const remarkMatch = String(data.comment)
        .toLowerCase()
        .includes(formattedSearchTerm);

      return (
        srNoMatch || creditMatch || debitMatch || balanceMatch || fromTo || remarkMatch
      );
    });

    setFilteredTableData(filteredData);
  };

  const tableHeader = [
    "Date",
    "Sr no.",
    "Credit",
    "Debit",
    "Balance",
    "Remark",
    "From / To",
  ];

  const tableRow = filteredTableData.map((data, index) => ({
    Date: new Date(data.createdAt).toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }),
    "Sr no.": index + 1,
    Credit: data.creditInfo || 0,
    Debit: data.debitInfo || 0,
    Balance: data.currentBalance || 0,
    Remark: data.comment || "",
    "From / To": data.fromTo === "/" ? "" : data.fromTo || "",
    type: data.type,
    accountLogId: data.accountLogId,
  }));

  useEffect(() => {
    getAccountStatementData();
  }, []);

  const getAccountLogData = async (id, TYPE = 'ALL') => {
    setSelectedLogId(id);
    const res = await getBetsFromAccountLogs(
      selectedLogId ? selectedLogId : id
    );
    if (res.code === 200) {
      let response_ = res.response;
      let betResponseDTOList = response_.betResponseDTOList;
      let betResponseDTOList_ = [];
      betResponseDTOList.forEach((item) => {
        if (item.back_rate && item.event_type == 'Casino') {
          item.type = 'back';
          item.rate = item.back_rate;
        } else if (item.lay_rate && item.event_type == 'Casino') {
          item.type = 'lay';
          item.rate = item.lay_rate;
        }
        if (
          item.back_run &&
          (item.event_type == 'Cricket' ||
            item.event_type == 'Soccer' ||
            item.event_type == 'Tennis')
        ) {
          item.type = 'back';
          item.rate = item.back_run;
        } else if (
          item.lay_run &&
          (item.event_type == 'Cricket' ||
            item.event_type == 'Soccer' ||
            item.event_type == 'Tennis')
        ) {
          item.type = 'lay';
          item.rate = item.lay_run;
        }
        betResponseDTOList_.push(item);
      });
      response_.betResponseDTOList = betResponseDTOList_;

      if (TYPE == 'ALL') {
        response_ = res.response;
      }
      if (TYPE == 'BACK') {
        response_.betResponseDTOList = response_.betResponseDTOList.filter(
          (item) => item.type == 'back'
        );
      }
      if (TYPE == 'LAY') {
        response_.betResponseDTOList = response_.betResponseDTOList.filter(
          (item) => item.type == 'lay'
        );
      }
      let map = {};
      map[selectedLogId ? selectedLogId : id] = response_;
      setLogData(map);
      setIsModalOpen(true);
    }
  };

  const handleRemarkClick = (id) => {
    setSelectedLogId(id);
    console.log("Remark clicked with ID:", id);
    getAccountLogData(id);
  };

  return (
    <React.Fragment>
      <Stack className="accounts-page-main-stack">
        <SearchWithText
          text={"Account Statement"}
          onSearchClick={(e) => handleSearch(e.target.value)}
        />
        <Stack className="accounts-page-date-stack">
          <BasicDatePicker
            text={"From"}
            onChange={handleFromDateChange}
            isFromDate={true}
          />
          <BasicDatePicker
            text={"To"}
            onChange={handleToDateChange}
            isFromDate={false}
          />

          <Stack className="select-main-stack" direction={"row"}>
            <SelectType
              selectedType={selectedType}
              handleTypeChange={handleTypeChange}
              options={options}
            />
            <Stack className="submit-button-stack" direction={"row"}>
              <Button
                variant="contained"
                onClick={getAccountStatementData}
                className="submit-button"
                disableRipple
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className="accounts-page-main-stack-1">
        <GenericTable
          tableHeader={tableHeader}
          tableRow={tableRow}
          onRemarkClick={handleRemarkClick}
        />
      </Stack>

      {logData[selectedLogId] && (
        <Modal
          size={'3xl'}
          onClose={() => {
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          motionPreset='slideInBottom'
          scrollBehavior='inside'
        >
          <ModalOverlay />
          <ModalContent marginTop={10} bg={'rgb(51,51,51)'}>
            <ModalCloseButton />
            <ModalBody p='2' bg={'#2E3439'} borderRadius={'5px'}>
              <div
                id='__BVID__2570___BV_modal_content_'
                tabIndex={-1}
                className='modal-content snipcss-z5ed3'
              >
                <header
                  id='__BVID__2570___BV_modal_header_'
                  className='modal-header'
                >
                  <h5
                    id='__BVID__2570___BV_modal_title_'
                    className='modal-title'
                  >
                    Result
                  </h5>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    type='button'
                    aria-label='Close'
                    className='close'
                  >
                    ×
                  </button>
                </header>
                <div id='__BVID__2570___BV_modal_body_' className='modal-body'>
                  <div className='mt-1'>{logData[selectedLogId]?.header}</div>
                  <div className='mt-1'>
                    <div className='row'>
                      <div className='col-6'>
                        Winner: {logData[selectedLogId]?.winner}
                      </div>
                      <div className='col-6 text-right'>
                        Game Time:
                        <span>
                          {moment(logData[selectedLogId]?.eventDateTime).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='mt-2'>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          type='radio'
                          id='soda-all'
                          name='example'
                          onClick={() => getAccountLogData(selectedLogId, 'ALL')}
                          defaultValue='all'
                          defaultChecked={selectedType == 'ALL' ? true : false}
                          className='custom-control-input'
                        />
                        <label
                          htmlFor='soda-all'
                          className='custom-control-label'
                        >
                          All
                        </label>
                      </div>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          type='radio'
                          id='soda-back'
                          name='example'
                          defaultValue='back'
                          onClick={() => getAccountLogData(selectedLogId, 'BACK')}
                          className='custom-control-input'
                          defaultChecked={selectedType == 'BACK' ? true : false}
                        />
                        <label
                          htmlFor='soda-back'
                          className='custom-control-label'
                        >
                          Back
                        </label>
                      </div>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          type='radio'
                          id='soda-lay'
                          name='example'
                          onClick={() => getAccountLogData(selectedLogId, 'LAY')}
                          defaultValue='lay'
                          className='custom-control-input'
                          defaultChecked={selectedType == 'LAY' ? true : false}
                        />
                        <label
                          htmlFor='soda-lay'
                          className='custom-control-label'
                        >
                          Lay
                        </label>
                      </div>

                      <div className='custom-control-inline total-soda'>
                        <div>
                          Total Bets:
                          <span className='text-success mr-2'>
                            {logData[selectedLogId]?.totalBet}
                          </span>
                          Total Win:
                          <span className='text-success'>
                            {logData[selectedLogId]?.totalWin}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive report-table-modal mt-2 report-table'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th className='bet-nation'>
                              <div>Nation</div>
                            </th>
                            <th className='text-right bet-user-rate'>
                              <div>Rate</div>
                            </th>
                            <th className='text-right bet-user-rate'>
                              <div>Bhav</div>
                            </th>
                            <th className='text-right bet-amount'>
                              <div>Amount</div>
                            </th>
                            <th className='text-right bet-amount'>
                              <div>Win</div>
                            </th>
                            <th className='bet-date'>
                              <div>Date</div>
                            </th>
                            <th className='bet-ip'>
                              <div>Ip Address</div>
                            </th>
                            <th className='bet-remark'>
                              <div>Browser Details</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {logData[selectedLogId]?.betResponseDTOList?.map(
                            (log) => (
                              <tr
                                className={` ${log.type == 'lay'
                                  ? 'lay-border'
                                  : 'back-border'
                                  }`}
                              >
                                <td className='bet-nation'>
                                  <div>
                                    <div className='d-inline-block vm custom-control custom-checkbox'>
                                      <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        defaultValue={0}
                                        id='__BVID__2616'
                                      />
                                      <label
                                        className='custom-control-label'
                                        htmlFor='__BVID__2616'
                                      ></label>
                                    </div>
                                    <span className='d-inline'>
                                      {log?.runner_name}
                                    </span>
                                  </div>
                                </td>
                                <td className='text-right bet-user-rate'>
                                  <div>{log?.rate}</div>
                                </td>
                                <td className='text-right bet-user-rate'>
                                  <div>
                                    {log?.back_rate
                                      ? log.back_rate
                                      : log.lay_rate
                                        ? log.lay_rate
                                        : '-'}
                                  </div>
                                </td>
                                <td className='text-right bet-amount'>
                                  <div>{log.amount}</div>
                                </td>
                                <td className='text-right bet-amount'>
                                  <div>
                                    {log.loss ? `-${log.loss}` : log.profit}
                                  </div>
                                </td>
                                <td className='bet-date'>
                                  <div>
                                    {moment(log.created_date + 'Z').format(
                                      'DD/MM/YYYY hh:mm'
                                    )}
                                  </div>
                                </td>
                                <td className='bet-ip'>
                                  <div>{log.ipAddress}</div>
                                </td>
                                <td className='bet-remark'>
                                  <Popover preventOverflow={true}>
                                    <PopoverTrigger>
                                      <Text
                                        _hover={{ cursor: 'pointer' }}
                                        color='blue.500'
                                      >
                                        <u title='Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36'>
                                          Detail
                                        </u>
                                      </Text>
                                    </PopoverTrigger>
                                    <PopoverContent bg='gray'>
                                      {log?.browserDetails}
                                    </PopoverContent>
                                  </Popover>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </React.Fragment>
  );
};
