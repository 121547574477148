import React, { useEffect } from "react";
import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Live from "../../components/live";
import cricket from "../../assets/images/icons/Group.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import OddsBox from "../game/Ui/OddsBox";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import FootballMenuIcon from "../../components/Icons/FootballMenu";
import TennisMenuIcon from "../../components/Icons/TennisMenu";
import {
  getCricketInPlayMatches,
  getTennisInPlayMatches,
} from "../../service/cricket";
import { getInplayMatches } from "../../redux/features/gameSlice";

export default function InPlayMatchListing({ id, bg, icon, sports }) {
  const { sportName } = useParams();
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  let _sportName = sportName;
  if (sports) {
    _sportName = sports;
  }
  const data_ = useSelector(
    (state) => state.gameData[_sportName + "InPlayGames"]
  );
  const data = useMemo(() => data_, [data_]);

  const getSportsImage = (sportName) => {
    switch (sportName) {
      case 'cricket':
        return (
          <img
            src={cricket}
            alt='Cricket'
            width={'24.34px'}
            style={{ height: '24px' }}
          />
        );
      case 'soccer':
        return <FootballMenuIcon />;
      case 'tennis':
        return <TennisMenuIcon />;
      default:
        return null;
    }
  };

  const navigateToGame = (gameEvent) => {
    if (_sportName == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(
        `/game/${_sportName}/${gameEvent[_sportName + "Id"]}?gameId=${
          gameEvent.gameId || gameEvent.eventId
        }`,
        {
          state: { gameEvent },
        }
      );
    }
  };

  useEffect(() => {
    if (!data || (data && data.length === 0)) {
      localStorage.setItem("no-data", true);
    } else {
      dispatch({
        type: "gameData/setIsDataLoaded",
        payload: true,
      });
      localStorage.removeItem("no-data");
    }
  }, [data]);

  const getCricketInPlayMatchesHandler = async () => {
    const res = await getCricketInPlayMatches();

    if (res.status === 200) {
      dispatch({
        type: "gameData/setCricketInPlayGames",
        payload: res.data.response,
      });
    }
  };

  const getTennisInPlayMatchesHandler = async () => {
    const res = await getTennisInPlayMatches();

    if (res.status === 200) {
      console.log(res, "resDataTennisSoccer");
      if (sports === "tennis") {
        dispatch({
          type: "gameData/setTennisInPlayGames",
          payload: res?.data?.response?.tennisAllMatchesResponseDTOS,
        });
      } else {
        dispatch({
          type: "gameData/setSoccerInPlayGames",
          payload: res?.data?.response?.soccerAllMatchesResponseDTOS,
        });
      }
    }
  };

  useEffect(() => {
    if (sports === "cricket") {
      getCricketInPlayMatchesHandler();
      dispatch(getInplayMatches("cricket"));
    }
    if (sports === "tennis" || sports === "soccer") {
      getTennisInPlayMatchesHandler();
      dispatch(getInplayMatches("tennis"));
    }
  }, [sports, dispatch]);

  return (
    <>
      <Box display={{ base: "block", md: "none" }}>
        {data ? (
          <>
            <Box display={{ base: "block", md: "none" }}>
              <div className="game-listing-container">
                {isEmpty(data) && (
                  <Text mt={3} className="norecords">
                    No Data Found
                  </Text>
                )}
                <div>
                  <div className="cricket-content">
                    {Array.isArray(data) &&
                      data.map((gameEvent, index) => (
                        <a
                          href="#"
                          onClick={() => navigateToGame(gameEvent)}
                          className="text-dark"
                        >
                          <div className="game-list pt-1 pb-1 container-fluid">
                            <div className="row row5">
                              <div className="col-8">
                                <p className="mb-0 game-name">
                                  <strong> {gameEvent.eventName}</strong>
                                </p>
                                <p className='mb-0' style={{ fontSize: 12 }}>
                                  {' '}
                                  {sports === 'tennis' ||
                                  sports === 'soccer' ? (
                                    <>
                                      {moment(gameEvent.eventDate).format(
                                        'MMM DD YYYY'
                                      )}{' '}
                                      {moment(gameEvent.eventDate).format(
                                        'hh:mm A zz'
                                      )}{' '}
                                    </>
                                  ) : (
                                    <>
                                      {moment(gameEvent.eventTime).format(
                                        'MMM DD YYYY'
                                      )}{' '}
                                      {moment(gameEvent.eventTime).format(
                                        'hh:mm A zz'
                                      )}{' '}
                                    </>
                                  )}
                                </p>
                              </div>
                              {/* <div className="col-4 text-right">
                                <div className="game-icons">
                                  <span className="game-icon">
                                    <span className="active-icon"></span>
                                  </span>{" "}
                                  <span className="game-icon">
                                    <i className="fas fa-tv"></i>
                                  </span>{" "}
                                  <span className="game-icon">
                                    <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/ic_fancy.png" />
                                  </span>{" "}
                                  <span className="game-icon vir-icon">
                                    <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/game-icon.svg" />
                                  </span>
                                </div>
                              </div> */}
                            </div>
                            <div className="row row5">
                              <div className="col-12">
                                <div className="text-center game-col game-home">
                                  <b>1</b>
                                </div>
                                <div className="text-center game-col game-home">
                                  <b>X</b>
                                </div>
                                <div className="text-center game-col game-home">
                                  <b>2</b>
                                </div>
                              </div>
                            </div>
                            <div className="row row5">
                              <div className="col-12">
                                <div className="text-center game-col game-home d-flex">
                                  <OddsBox
                                    match={gameEvent}
                                    oddsKey="back1"
                                    back={true}
                                    home={true}
                                    index={index}
                                  />
                                  <OddsBox
                                    match={gameEvent}
                                    oddsKey="lay1"
                                    back={false}
                                    home={true}
                                    index={index}
                                  />
                                </div>{" "}
                                <div className="text-center game-col game-home d-flex">
                                  <OddsBox
                                    match={gameEvent}
                                    oddsKey="back11"
                                    back={true}
                                    home={true}
                                    index={index}
                                  />
                                  <OddsBox
                                    match={gameEvent}
                                    oddsKey="lay11"
                                    back={false}
                                    home={true}
                                    index={index}
                                  />
                                </div>{" "}
                                <div className="text-center game-col game-home d-flex">
                                  <OddsBox
                                    match={gameEvent}
                                    oddsKey="back12"
                                    back={true}
                                    home={true}
                                    index={index}
                                  />
                                  <OddsBox
                                    match={gameEvent}
                                    oddsKey="lay12"
                                    back={false}
                                    home={true}
                                    index={index}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </Box>
          </>
        ) : (
          <Text mt={3} className="norecords">
            No Data Found
          </Text>
        )}
      </Box>

      <Box display={{ base: "none", md: "block" }}>
        <TableContainer>
          {data && data.length > 0 ? (
            <Table
              variant="simple"
              style={{ color: "#aaafb5" }}
              colorScheme="whiteAlpha"
              size="sm"
              bg="#fff"
            >
              {/* <Flex alignItems="center" mb={4}>
                {getSportsImage(sports)}
                <Text ml={2} className="sports-name">
                  {sports}
                </Text>
              </Flex> */}
              <Thead className={_sportName}>
                <Tr>
                  <Th
                    padding="10px"
                    w="55%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box
                      style={{ fontSize: 18 }}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      Match
                    </Box>
                  </Th>
                  <Th w='15%'>
                    <Text textColor='#262626' textAlign='center'>
                      1
                    </Text>
                  </Th>
                  <Th w="15%">
                    <Text textColor="#262626" textAlign="center">
                      X
                    </Text>
                  </Th>
                  <Th w="15%">
                    <Text textColor="#262626" textAlign="center">
                      2
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((gameEvent, index) => (
                  <Tr key={index}>
                    <Td
                      cursor='pointer'
                      onClick={() => navigateToGame(gameEvent)}
                      display='flex'
                      alignItems='center'
                    >
                      <Text mr='1'>
                        {gameEvent.eventName} <br />
                        {/* <span
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {gameEvent.seriesName
                    ? `(${gameEvent.seriesName})`
                    : ""}
                </span> */}
                      </Text>
                      <Spacer />
                      <Text
                        style={{ justifyContent: 'right', textAlign: 'right' }}
                      >
                        {sports === 'tennis' || sports === 'soccer' ? (
                          <>
                            {moment(gameEvent.eventDate).format('MMM DD YYYY')}{' '}
                            {moment(gameEvent.eventDate).format('hh:mm A zz')}{' '}
                          </>
                        ) : (
                          <>
                            {moment(gameEvent.eventTime).format('MMM DD YYYY')}{' '}
                            {moment(gameEvent.eventTime).format('hh:mm A zz')}{' '}
                          </>
                        )}
                      </Text>
                    </Td>
                    <Td>
                      <Box display='flex' flexDirection='row'>
                        <OddsBox
                          match={gameEvent}
                          oddsKey='back1'
                          back={true}
                          home={true}
                          index={index}
                        />
                        <OddsBox
                          match={gameEvent}
                          oddsKey='lay1'
                          back={false}
                          home={true}
                          index={index}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box display='flex' flexDirection='row'>
                        <OddsBox
                          match={gameEvent}
                          oddsKey='back11'
                          back={true}
                          home={true}
                          index={index}
                        />
                        <OddsBox
                          match={gameEvent}
                          oddsKey='lay11'
                          back={false}
                          home={true}
                          index={index}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box display='flex' flexDirection='row'>
                        <OddsBox
                          match={gameEvent}
                          oddsKey='back12'
                          back={true}
                          home={true}
                          index={index}
                        />
                        <OddsBox
                          match={gameEvent}
                          oddsKey='lay12'
                          back={false}
                          home={true}
                          index={index}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text className='norecords'>No Record Found !!!</Text>
          )}
        </TableContainer>
      </Box>
    </>
  );
}
