import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaLock,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import "./css/abj.css";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
export default function AndarBahar2() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.abjOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [plExposure, setPlExposure] = useState(null);
  const scrollRefPlayerA = React.useRef();
  const scrollRefPlayerB = React.useRef();
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = "ABJ";

  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  const handlePlayerClick = (toLeft = false, isPlayerA = true) => {
    let ref = isPlayerA ? scrollRefPlayerA : scrollRefPlayerB;
    if (toLeft) {
      ref.current.scrollLeft -= 100;
    } else {
      ref.current.scrollLeft += 100;
    }
  };

  useEffect(() => {
    dispatch(setGameKey("abj"));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box h="60vh" mb="2" pos="relative" className="casino-video">
          {odds?.cards && (
            <CardDrawer
              custom={
                odds?.cards.length > 0 && (
                  <Box>
                    <Flex ml="10px" alignItems="center">
                      <Image
                        className="casino-card-img"
                        h="40px"
                        mr="10px"
                        src={ASSETS_URL + odds?.cards[0][0] + ".png"}
                      />
                      <Box>
                        <Flex alignItems="center">
                          <Text mr="1">A</Text>
                          <FaArrowAltCircleLeft
                            onClick={() => handlePlayerClick(true, true)}
                          />
                          {odds?.cards[1]?.length >= 1 && (
                            <div
                              ref={scrollRefPlayerA}
                              className="scroll-container"
                            >
                              {odds?.cards[1]?.map((item, index) => (
                                <div className="scroll-inner-wrapper">
                                  <span>
                                    {item && (
                                      <img
                                        className="casino-card-img"
                                        src={ASSETS_URL + item + ".png"}
                                      />
                                    )}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                          <FaArrowAltCircleRight
                            onClick={() => handlePlayerClick(false, true)}
                          />
                        </Flex>
                        <Flex alignItems="center">
                          <Text mr="1">B</Text>
                          <FaArrowAltCircleLeft
                            onClick={() => handlePlayerClick(true, false)}
                          />
                          {odds?.cards[2]?.length >= 1 && (
                            <div
                              ref={scrollRefPlayerB}
                              className="scroll-container"
                            >
                              {odds?.cards[2]?.map(
                                (item, index) =>
                                  item != "1" && (
                                    <div className="scroll-inner-wrapper">
                                      <span>
                                        {item && (
                                          <img
                                            className="casino-card-img"
                                            src={ASSETS_URL + item + ".png"}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  )
                              )}
                            </div>
                          )}
                          <FaArrowAltCircleRight
                            onClick={() => handlePlayerClick(false, false)}
                          />
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                )
              }
            />
          )}
          <div class="market-title">Andar Bahar 2</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3043`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}abj-rules.jpg`} />
        </Box>
        <div className="snipcss-LtoGs">
          <div className="teen20casino-container">
            <div className="teen20left">
              <div className="ab2-title">A</div>
              <div
                onClick={() => setEvent("SA")}
                className={`sa-sb-box pr ${isLocked ? "suspended" : ""}`}
              >
                <div>SA</div>
                <div>{getData("SA").back1}</div>
                <div className="ab-book ">
                  {getExposureForEntity("SA")?.exposure}
                </div>
              </div>
              <div
                onClick={() => setEvent("1st Bet")}
                className={`ab2-bet pr  ${isLocked ? "suspended" : ""}`}
              >
                <div>First Bet</div>
                <div>{getData("1st Bet").back1}</div>
                <div className="ab-book ">
                  {getExposureForEntity("1st Bet")?.exposure}
                </div>
              </div>
              <div
                onClick={() => setEvent("2nd Bet")}
                className={`ab2-bet pr ${isLocked ? "suspended" : ""}`}
              >
                <div>Second Bet</div>
                <div>{getData("2nd Bet").back1}</div>
                <div className="ab-book ">
                  {getExposureForEntity("2nd Bet")?.exposure}
                </div>
              </div>
              <div className="ab2-title">A</div>
            </div>
            <div className="teen20center"></div>
            <div className="teen20right">
              <div className="ab2-title">B</div>
              <div
                onClick={() => setEvent("SB")}
                className={`sa-sb-box pr ${isLocked ? "suspended" : ""}`}
              >
                <div>SB</div>
                <div>{getData("SB").back1}</div>
                <div className="ab-book ">
                  {getExposureForEntity("SB")?.exposure}
                </div>
              </div>
              <div
                onClick={() => setEvent("1st Bet")}
                className={`ab2-bet pr  ${isLocked ? "suspended" : ""}`}
              >
                <div>First Bet</div>
                <div>{getData("1st Bet").back1}</div>
                <div className="ab-book ">
                  {getExposureForEntity("1st Bet")?.exposure}
                </div>
              </div>
              <div
                onClick={() => setEvent("2nd Bet")}
                className={`ab2-bet pr ${isLocked ? "suspended" : ""}`}
              >
                <div>Second Bet</div>
                <div>{getData("2nd Bet").back1}</div>
                <div className="ab-book">
                  {getExposureForEntity("2nd Bet")?.exposure}
                </div>
              </div>
              <div className="ab2-title">B</div>
            </div>
          </div>
          <div className="teen20casino-container">
            <div className="teen20left ab2oddeven">
              <div className="casino-box-row">
                <div className="casino-bl-box">
                  <div className="casino-bl-box-item">
                    <b>Odd</b>
                  </div>
                </div>
                <div className="casino-bl-box">
                  <div className="casino-bl-box-item">
                    <b>Even</b>
                  </div>
                </div>
              </div>
              <div className="casino-box-row">
                <div
                  onClick={() => setEvent("Joker Odd")}
                  className="casino-bl-box"
                >
                  <div
                    className={`back casino-bl-box-item ${
                      isLocked ? "suspended" : ""
                    }`}
                  >
                    <span className="casino-box-odd">
                      {getData("Joker Odd").back1}
                      <Text>
                        {" "}
                        {getExposureForEntity("Joker Odd")?.exposure}
                      </Text>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => setEvent("Joker Even")}
                  className="casino-bl-box"
                >
                  <div
                    className={`back casino-bl-box-item ${
                      isLocked ? "suspended" : ""
                    }`}
                  >
                    <span className="casino-box-odd">
                      {getData("Joker Even").back1}
                      <Text>
                        {" "}
                        {getExposureForEntity("Joker Even")?.exposure}
                      </Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="teen20center"></div>
            <div className="teen20left ab2cards">
              <div className="casino-box-row">
                <div className="casino-bl-box">
                  <div className="casino-bl-box-item casino-card-img">
                    <img src={ASSETS_URL + "spade.png"} />
                  </div>
                </div>
                <div className="casino-bl-box">
                  <div className="casino-bl-box-item casino-card-img">
                    <img src={ASSETS_URL + "club.png"} />
                  </div>
                </div>
                <div className="casino-bl-box">
                  <div className="casino-bl-box-item casino-card-img">
                    <img src={ASSETS_URL + "heart.png"} />
                  </div>
                </div>
                <div className="casino-bl-box">
                  <div className="casino-bl-box-item casino-card-img">
                    <img src={ASSETS_URL + "diamond.png"} />
                  </div>
                </div>
              </div>
              <div className="casino-box-row">
                {["Spade", "Club", "Heart", "Diamond"].map((card) => (
                  <div
                    onClick={() => setEvent(`Joker ${card}`)}
                    className={`casino-bl-box ${isLocked ? "suspended" : ""}`}
                  >
                    <div className="back casino-bl-box-item">
                      <span className="casino-box-odd">
                        {getData(`Joker ${card}`).back1}
                        <Text>
                          {" "}
                          {getExposureForEntity(`Joker ${card}`)?.exposure}
                        </Text>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="teen20casino-container ab2allcards">
            <div className="text-center w-100">
              <div
                className="casino-bl-box casino-cards-odds-title"
                style={{ backgroundColor: "#EEE" }}
              >
                <div className="casino-bl-box-item">
                  <b>12</b>
                </div>
              </div>
            </div>

            <div className="casino-cards text-center mt-1">
              {["A", 2, 3, 4, 5, 6, 7, 8, 9, 10, "J", "Q", "K"].map((card) => (
                <div
                  onClick={() => setEvent(`Joker ${card}`)}
                  className="casino-card-item"
                >
                  <div className={`card-image ${isLocked ? "suspended" : ""}`}>
                    <img src={ASSETS_URL + card + ".png"} alt="image" />
                  </div>
                  <div className="casino-book ">
                    {getExposureForEntity(`Joker ${card}`)?.exposure}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId="ABJ" />
    </Box>
  );
}
