import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  useColorModeValue,
  Checkbox,
  Spacer,
} from '@chakra-ui/react';
import { BsFillPersonFill, BsFillLockFill } from 'react-icons/bs';
import { FaEye, FaEyeSlash, FaHandPointDown, FaKey, FaSignInAlt } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';
import CustomInput from '../Input';
import { Encrypt } from '../../utils/constants';
import { useState } from 'react';
import { loginAPI } from '../../service/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { JSEncrypt } from 'jsencrypt';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/logo.svg';
import './login.scss';
import { FiEye, FiEyeOff } from 'react-icons/fi';

export default function LoginCard() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [ageAgree, setAgeAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loginHandler = async () => {
    if (!ageAgree) {
      return toast({
        title: 'Warning',
        description: 'Minimum age 18 is required!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }
    const res = await loginAPI(userName, Encrypt(password));
    console.log(res, 'loginResponse');
    console.log(res.data, 'response');
    const resData = res.data;
    if (resData.code === 200) {
      if (resData.response.mfaEnabled) {
        return navigate(`/loginauth/2?user=${userName}`);
      }
      localStorage.setItem('token', resData?.response?.token);
      localStorage.setItem('refCode', resData?.response?.refferalCode || "");
      localStorage.setItem('user', JSON.stringify(resData?.response.username));
      dispatch({
        type: 'accountData/setUserData',
        payload: resData?.response,
      });
      toast({
        title: 'Success',
        description: 'Success',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } else if (resData.code === 403) {
      toast({
        title: 'Error',
        description: resData?.response || 'Somthing went wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else if (resData.code === 401) {
      toast({
        title: 'Error',
        description:
          resData?.response || 'Unauthorized, Enter Correct credentials',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else if (resData.code === 500) {
      toast({
        title: 'Error',
        description:
          resData?.response || 'Unauthorized, Enter Correct credentials',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error',
        description: resData?.error || 'Something went wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={2} fontSize='sm' mx={'auto'} maxW={'422px'}>
        <Stack align={'center'}>
          <img className='logo' alt='logo' src={Logo} />
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Text className='login-text'>LOGIN</Text>
          <Stack spacing={4}>
            <CustomInput
              label='Username'
              type={'text'}
              placeholder={'Username'}
              name='username'
              iconPosition='right'
              onChange={(e) => setUserName(e.target.value)}
              icon={
                <Box fontSize='24px'>
                  <BsFillPersonFill />
                </Box>
              }
            />
            {/* <CustomInput
              type={showPassword ? 'text' : 'password'}
              label='Password'
              placeholder={'Password'}
              name='password'
              onChange={(e) => setPassword(e.target.value)}
              iconPosition='right'
              icon={
                <Box
                  fontSize='18px'
                  cursor={'pointer'}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </Box>
              }
            /> */}
             <CustomInput
              type={showPassword ? "text" : "password"}
              label="Password"
              placeholder={"Password"}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              icon={showPassword ? <FaEyeSlash onClick={() => setShowPassword(false)} /> : <FaEye onClick={() => setShowPassword(true)} />}
              iconPosition="right"
            />

            <Box className='agree-box'>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <input
                  onChange={(e) => setAgeAgree(e.target.checked)}
                  type='checkbox'
                  id='agreeCheckbox'
                />
                <label htmlFor='agreeCheckbox'>
                  <p
                    style={{
                      fontFamily: "'Inter',Helvetica",
                      fontSize: '11px',
                      fontWeight: 400,
                    }}
                  >
                    I am at least <span>18 years</span> of age and I have read,
                    accept and agree to the{' '}
                    <a href='www.google.com'>Terms and Conditions</a>,{' '}
                    <a href>Responsible Gaming,</a>
                    <a href='www.google.com'>GamCare,</a>{' '}
                    <a href='www.google.com'>Gambling Therapy.</a>
                  </p>
                </label>
              </div>
            </Box>
            <Stack spacing={10}>
              <Button
                onClick={loginHandler}
                display='flex'
                justifyContent='center'
                alignItems='center'
                backgroundColor={'#102a6d'}
                borderRadius={'10px'}
                height={'44px'}
              >
                <Text
                  style={{
                    fontFamily: "'Inter',Helvetica",
                    fontSize: '16px',
                    fontWeight: 600,
                    color: 'white',
                  }}
                >
                  Login
                </Text>
              </Button>
            </Stack>
            <div style={{ display: 'flex' }}>
              <Text
                style={{
                  justifyContent: 'left',
                  textAlign: 'left',
                  color: 'blue',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/forgot-password')}
              >
                Forgot Password
              </Text>
              <Spacer />
              <Text style={{ justifyContent: 'right', textAlign: 'right' }}>
                Don't have account ?{' '}
                <span
                  onClick={() => navigate('/sign-up')}
                  style={{ color: 'blue', cursor: 'pointer' }}
                >
                  Sign Up
                </span>
              </Text>
            </div>
            <small
              class='recaptchaTerms'
              style={{
                fontFamily: "'Inter',Helvetica",
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              This site is protected by reCAPTCHA and the Google
              <a
                href='https://policies.google.com/privacy'
                style={{
                  fontFamily: "'Inter',Helvetica",
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Privacy Policy
              </a>
              and
              <a
                href='https://policies.google.com/terms'
                style={{
                  fontFamily: "'Inter',Helvetica",
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Terms of Service
              </a>
              apply.
            </small>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
