import React, { useEffect } from "react";

export default function Video() {
  const videoRef = React.useRef(null);
  const [video, setVideo] = React.useState(null);

  return (
    <div>
      <iframe src="https://gbsroute.casinovid.in/supervid/?id=3035"></iframe>
    </div>
  );
}
