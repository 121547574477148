import {
  Box,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import "./fixture.css";
import { useEffect } from "react";

export default function CustomTable({
  rows,
  columns,
  showPagination,
  totalPages_,
  rowsPerPage_,
  currentPage_,
  headerBg,
  ref,
  onPageChange,
}) {
  const [currentPage, setCurrentPage] = React.useState(currentPage_);
  const [totalPages, setTotalPages] = React.useState(totalPages_);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPage_);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);

  React.useEffect(() => {
    if (rows) {
      setRowsPerPageOptions([5, 10, 20, 50, 100]);
    }
  }, [rows, rowsPerPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  useEffect(() => {
    setTotalPages(totalPages_);
  }, [totalPages_]);
  return (
    <TableContainer ref={ref}>
      <Table className="custom-table">
        <Thead>
          <Tr border="none">
            {columns.map((column) => (
              <Th border="none" color="lightgray">
                {column.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows ? (
            rows.map((row, index) => (
              <Tr border="none">
                {columns.map((column) =>
                  column.element ? (
                    column.element(row, index)
                  ) : column.type == "date" ? (
                    <Td border="none">
                      <Text color="#A6A6A6">{new Date(row[column.id])}</Text>
                    </Td>
                  ) : (
                    <>
                      <Td border="none">
                        {typeof row[column.id] == "string" ||
                        typeof row[column.id] == "number" ? (
                          <Text fontSize="sm">{row[column.id]}</Text>
                        ) : typeof row[column.id] == "boolean" ? (
                          <Switch
                            size="sm"
                            defaultChecked={
                              column.id == "isBetLocked"
                                ? !row[column.id]
                                : row[column.id]
                            }
                            isDisabled
                          />
                        ) : null}
                      </Td>
                    </>
                  )
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td border="none" colSpan={columns.length}>
                <Box textAlign="center">
                  <Text>No data</Text>
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
        {showPagination && (
          <Tfoot>
            <Tr>
              <Th colSpan={columns.length}>
                <Box className="pagination-container">
                  <Text className="pagination-text">
                    Page {currentPage} of {totalPages}
                  </Text>
                  <Box gap={5}>
                    <button
                      className="pagination-button"
                      onClick={prevPage}
                      disabled={currentPage === 0}
                    >
                      <i className="fa fa-chevron-left"></i> Prev
                    </button>
                    <button
                      className="pagination-button"
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next <i className="fa fa-chevron-right"></i>
                    </button>
                  </Box>
                </Box>
              </Th>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
}
