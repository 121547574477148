import { AXIOS_INSTANCE } from '.';
import { BASE_URL_BANKING } from '../utils/constants';

export const paymentDetailsApi = async () => {
  try {
    const res = await AXIOS_INSTANCE.get(
      `${BASE_URL_BANKING}/api/acc/payment-details`
    );

    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
