import React from "react";
import { Stack, Typography } from "@mui/material";
import './faq.scss'

export const FAQText = ({ srNo, text }) => {
  return (
    <Stack className="main-text-header">
      <Typography className="header-text-style" variant="link">
        {srNo}. Follow this video tutorial when the game does not work in {text} 
      </Typography>
    </Stack>
  );
};
