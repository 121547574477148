import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import {
  BsFileEarmarkExcel,
  BsFileEarmarkPdf,
  BsFileExcelFill,
  BsFilePdfFill,
} from 'react-icons/bs';
import CustomTable from '../../components/Table';
import { betHistoryAPI, currentBetsAPI } from '../../service/AuthService';
import CustomInput from '../../components/CustomInput';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { buttonColors, exportToCsvReactCsv } from '../../utils/constants';
import { useState } from 'react';
import CustomSelect from '../../components/CustomSelect';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function BetHistory() {
  const userData = useSelector((state) => state.account.userData);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [data, setData] = React.useState([]);
  const [betType, setBetType] = React.useState('ALL');
  const [search, setSearch] = useState('');
  const [betStatus, setBetStatus] = React.useState('MATCHED');
  const [totalElements, setTotalElements] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [totalBets_, setTotalBets] = React.useState(0);
  const [totalAmount_, setTotalAmount] = React.useState(0);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [csvReport, setCsvReport] = React.useState([]);
  const [reportType, setReportType] = React.useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [type, setType] = React.useState('CRICKET');
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
  const columns = [
    // {
    //   name: "Sports",
    //   id: "event_type",
    //   element: (item) => (
    //     <Td
    //       border="none"
    //       borderLeft={`5px solid ${
    //         item?.type == "BACK" ? "rgb(114, 187, 239)" : "rgb(249, 148, 186)"
    //       }`}
    //     >
    //       <Text>{item.event_type}</Text>
    //     </Td>
    //   ),
    // },
    {
      name: 'Event Name',
      id: 'event_name',
    },
    {
      name: 'Nation',
      id: 'runner_name',
    },
    {
      name: 'Bet Type',
      id: 'runner_name',
    },
    {
      name: 'User Rate',
      id: 'lay_rate',
    },
    {
      name: 'Amount',
      id: 'amount',
    },
    {
      name: 'Profit/Loss',
      id: 'profit',
    },
    {
      name: 'Place Date',
      id: 'created_date',
      element: (item) => (
        <Td border='none'>
          <Text>
            {moment(item.created_date + 'Z').format('DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Td>
      ),
    },
    {
      name: 'Match Date',
      id: 'created_date',
      element: (item) => (
        <Td border='none'>
          <Text>
            {moment(item.created_date + 'Z').format('DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Td>
      ),
    },
  ];
  const isMobileView = window.innerWidth < 768;

  const betHistoryAPIHandler = useCallback(async () => {
    const payload = {
      from: moment(startDate).toISOString(),
      matched: betStatus === 'MATCHED',
      page: 0,
      size: 10,
      sport: type,
      to: moment(endDate).toISOString(),
      userName: userData.username,
    };
    const res = await betHistoryAPI(payload);
    console.log(res, 'resData');
    if (res.status === 200) {
      setData(res.data.response.content);
    }
  }, [betStatus, endDate, startDate, type, userData.username]);

  // useEffect(() => {
  //   betHistoryAPIHandler();
  // }, [betHistoryAPIHandler]);

  const handleSubmit = (e) => {
    e.preventDefault();
    betHistoryAPIHandler();
  };

  return (
    <Box
      p={{ base: 0, md: 2 }}
      fontSize='sm'
      textColor='lightgray'
      className='setting-page-main bet-history-main'
    >
      <Box
        p='2'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        w='100%'
        color='white'
        background='#ae4600bd'
        padding={2}
        marginTop='-5px'
      >
        <Text textColor='white' fontSize='xl' color='#fff !important'>
          Bet History
        </Text>
      </Box>
      <Box
        w='100%'
        display='flex'
        alignItems='center'
        justifyContent={{
          base: 'space-between',
          md: 'flex-start',
        }}
      >
        <Box
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          my='2'
          width={'100%'}
          className='input-box-head'
        >
          <CustomSelect
            borderRadius='5'
            textColor='#AAA391'
            containerStyle={{
              margin: 5,
              width: isMobileView ? '50%' : '40rem',
            }}
            size='md'
            borderColor='#ccc'
            onChange={(e) => setType(e.target.value)}
            options={[
              {
                label: 'Sports Type',
                value: 'ALL',
              },
              {
                label: 'Football',
                value: 'SOCCER',
              },
              {
                label: 'Tennis',
                value: 'TENNIS',
              },
              {
                label: 'Cricket',
                value: 'CRICKET',
              },
            ]}
            value={type}
            className='col-select'
          ></CustomSelect>
          <CustomSelect
            borderRadius='5'
            textColor='#AAA391'
            containerStyle={{
              margin: 5,
              width: isMobileView ? '50%' : '40rem',
            }}
            size='md'
            borderColor='#ccc'
            onChange={(e) => setBetStatus(e.target.value)}
            options={[
              {
                label: 'Bet Status',
                value: 'none',
              },
              {
                label: 'Matched',
                value: 'MATCHED',
              },
              {
                label: 'Deleted',
                value: 'DELETED',
              },
            ]}
            value={betStatus}
            className='col-select'
          ></CustomSelect>
          <CustomInput
            placeholder='Select Start Date'
            type='date'
            bg='transparent'
            _placeholder={{ color: 'gray' }}
            _focus={{ outline: 'none' }}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
              width: isMobileView ? '50%' : '40rem',
            }}
            textColor='#AAA391'
            size='md'
            borderRadius='5'
            max={moment().format('YYYY-MM-DD')}
            className='col-select'
          />
          <CustomInput
            placeholder='Select Start Date'
            type='date'
            bg='transparent'
            borderRadius='5'
            _placeholder={{ color: 'gray' }}
            _focus={{ outline: 'none' }}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor='#AAA391'
            containerStyle={{
              margin: 5,
              width: isMobileView ? '50%' : '40rem',
            }}
            size='md'
            max={moment().format('YYYY-MM-DD')}
            min={moment(startDate).format('YYYY-MM-DD')}
            className='col-select'
          />

          <Button
            px='80px'
            py='20px'
            mx='2'
            size='sm'
            className='blue-btn'
            bg={buttonColors}
            onClick={handleSubmit}
            alignSelf='center'
            color='black'
            borderRadius={5}
            w={{ base: '100%', md: 'auto' }}
          >
            Submit
          </Button>
        </Box>
      </Box>

      <CustomTable
        headerBg='#363636'
        columns={columns}
        rows={data}
        showPagination={true}
        rowsPerPage_={pageSize}
        totalPages_={totalElements}
        currentPage_={pageNumber}
      />
      {/* <ul className="pagination justify-content-center mt-2 snipcss-ZlA51">
        <li
          onClick={() => {
            if (pageNumber > 0) {
              setPageNumber(0);
            }
          }}
          className="page-item "
        >
          <a tabIndex={-1} className="page-link">
            &lt;&lt;
          </a>
        </li>
        <li
          onClick={() => {
            if (pageNumber > 0) {
              setPageNumber(pageNumber - 1);
            }
          }}
          className="page-item "
        >
          <a tabIndex={-1} className="page-link">
            &lt;
          </a>
        </li>
        {Array.from({ length: noOfPages }, (_, i) => i + 1).map(
          (item, index) => (
            <li className={`page-item ${index == pageNumber ? "active" : ""}`}>
              <a tabIndex={0} className="page-link">
                {index + 1}
              </a>
            </li>
          )
        )}
        <li
          onClick={() => {
            if (pageNumber < noOfPages - 1) {
              setPageNumber(pageNumber + 1);
            }
          }}
          className="page-item "
        >
          <a tabIndex={-1} className="page-link">
            &gt;
          </a>
        </li>
        <li
          onClick={() => {
            if (pageNumber < noOfPages - 1) {
              setPageNumber(noOfPages - 1);
            }
          }}
          className="page-item "
        >
          <a tabIndex={-1} className="page-link">
            &gt;&gt;
          </a>
        </li>
      </ul> */}
    </Box>
  );
}
