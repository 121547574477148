import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Collapse,
  VStack,
  Link,
  Box,
  Divider, // Import Divider from Chakra UI
} from "@chakra-ui/react";
import { FaBars, FaTimes, FaAngleRight, FaAngleDown } from "react-icons/fa";
import Rules from "./Rules";
import Logo from "../../assets/img/logo.svg";
import "./header.css";

const MobileHeader = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const { isOpen: rulesOpen, onOpen: openRules, onClose: closeRules } = useDisclosure();
  const { isOpen: drawerOpen, onOpen: openDrawer, onClose: closeDrawer } = useDisclosure();
  const [subMenuOpen, setSubMenuOpen] = useState({ myAccount: false, myUsers: false, myPoints: false });

  const permissions = JSON.parse(localStorage.getItem('state'));
  const canAddNewUser = permissions?.account?.userData?.canCreateUser;
  const canUseBanking = permissions?.account?.userData?.canUseBanking;

  const handleLogout = () => {
    dispatch({ type: "gameData/logOut" });
    dispatch({ type: "accountData/logOut" });
    localStorage.clear();
    window.location.href = "/";
  };

  const toggleSubMenu = (menu) => {
    setSubMenuOpen((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  const handleDrawerOpen = () => {
    closeRules(); // Close Rules if it's open
    openDrawer(); // Open the Drawer
  };

  return (
    <div>
      <Rules isOpen={rulesOpen} onClose={closeRules} />
      <header className="mob-header">
        <Flex justify="row row5 pt-1 pb-1">
          <Button
            variant="unstyled"
            className="drawer-hamburger-menu white m-2"
            onClick={handleDrawerOpen}
          >
            <FaBars />
          </Button>
          <a href="/" style={{ marginTop: '20px' }}>
            <img src={Logo} alt="Exchange" className="drawer-logo" />
          </a>
          <Flex direction="column" align="end">
            <Text className="drawer-username white">
              <b>{userData.username}</b>
            </Text>
            <Text className="drawer-balance mb-0 white">
              <i className="fas fa-landmark mr-1"></i> <b>{userData.balance}</b>
            </Text>
            <Text className="drawer-liability white">
              <b>Exp: {userData.liability}</b>
            </Text>
          </Flex>
        </Flex>
      </header>

      {/* Drawer Component */}
      <Drawer isOpen={drawerOpen} placement="left" onClose={closeDrawer} size="full">
        <DrawerOverlay />
        <DrawerContent className="drawer-content">
          <DrawerHeader className="drawer-sub-menu">
            <Flex justify="space-between" align="center">
              <Text fontSize="lg">Menu</Text>
              <Button onClick={closeDrawer}>
                <FaTimes />
              </Button>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4} p={4}>
              {/* My Account Section */}
              <Box width="100%">
                <Flex
                  align="center"
                  justify="space-between"
                  onClick={() => toggleSubMenu('myAccount')}
                  cursor="pointer"
                >
                  <Link href="javascript:void(0)">My Account</Link>
                  {subMenuOpen.myAccount ? <FaAngleDown /> : <FaAngleRight />}
                </Flex>
                <Collapse in={subMenuOpen.myAccount}>
                  <VStack className="drawer-sub-menu" align="start" ml={4} spacing={2}>
                    <Link href="/report/account-statement">Account Statement</Link>
                    {canUseBanking &&
                      <Link href="/sendMoney">Send Money</Link>
                    }
                    <Link href="/bet_history">Bet History</Link>
                    <Link href="/current">Current Bets</Link>
                    <Link href="/casino-bets">Live Casino Bets</Link>
                    <Link href="/casino-result">Casino Results</Link>
                  </VStack>
                </Collapse>
                {/* Divider */}
                <Divider orientation="horizontal" />
              </Box>

              {/* My Users Section */}
              {canAddNewUser && 
              <Box width="100%">
                <Flex
                  align="center"
                  justify="space-between"
                  onClick={() => toggleSubMenu('myUsers')}
                  cursor="pointer"
                >
                  <Link href="javascript:void(0)">My Users</Link>
                  {subMenuOpen.myUsers ? <FaAngleDown /> : <FaAngleRight />}
                </Flex>
                <Collapse in={subMenuOpen.myUsers}>
                  <VStack className="drawer-sub-menu" align="start" ml={4} spacing={2}>
                    <Link href="/add-newUser">Add New Member</Link>
                    <Link href="/direct-users">Direct User</Link>
                  </VStack>
                </Collapse>
                {/* Divider */}
                <Divider orientation="horizontal" />
              </Box>}

              {/* My Points Section */}
              {canUseBanking &&
              <Box width="100%">
                <Flex
                  align="center"
                  justify="space-between"
                  onClick={() => toggleSubMenu('myPoints')}
                  cursor="pointer"
                >
                  <Link href="javascript:void(0)">My Points</Link>
                  {subMenuOpen.myPoints ? <FaAngleDown /> : <FaAngleRight />}
                </Flex>
                <Collapse in={subMenuOpen.myPoints}>
                  <VStack className="drawer-sub-menu" align="start" ml={4} spacing={2}>
                    <Link href="/deposit">Deposit</Link>
                    <Link href="/withdrawal">Withdrawal</Link>
                    <Link href="/bank-account">Bank Account</Link>
                    <Link href="/deposit-history">Deposit History</Link>
                    <Link href="/withdrawal-history">Withdrawal History</Link>
                  </VStack>
                </Collapse>
                {/* Divider */}
                <Divider orientation="horizontal" />
              </Box>}

              <Box>
                <Link href="/change-password">Change Password</Link>
                <Divider orientation="horizontal" />
              </Box>

              <Box>
                <Link href="javascript:void(0)" onClick={openRules}>
                  Rules
                </Link>
                <Divider orientation="horizontal" />
              </Box>

              <Box>
                <Link href="/">Home</Link>
                <Divider orientation="horizontal" />
              </Box>

              <Link href="#" onClick={handleLogout} color="red.500">
                Logout
              </Link>

            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </div>
  );
};

export default MobileHeader;