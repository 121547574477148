import React, { useState } from "react";
import {
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { radioOptions } from "./index.utils";
import "../../../assets/styles/css/radioGroups.scss";

const RadioGroups = ({ onChange }) => {
  const initialValue = radioOptions[0].value;
  const [radioValue, setRadioValue] = useState(initialValue);
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    onChange(event);
  };

  return (
    <Stack className="radio-group-main-container">
      <FormControl className="radio-group-main-form-control">
        <RadioGroup
          row
          value={radioValue}
          className="radio-group-main-radio"

        >
          {radioOptions.map((radioOption) => (
            <FormControlLabel
              key={radioOption.value}
              value={radioOption.value}
              control={<Radio />}
              onChange={handleRadioChange}
              label={radioOption.label}
              className="radio-button-label"
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default RadioGroups;
