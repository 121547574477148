/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Divider } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './header.css';
import { FiMenu } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getAnnouncementAPI, searchAPI } from '../../service/AuthService';
import { useSearchParams } from 'react-router-dom';
import Rules from './Rules';
import Logo from '../../assets/img/logo.svg';
import PersonIcon from '../../assets/img/Frame.png';
import { fontWeight } from '@mui/system';

const Header = ({ setIsSidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isMenuOpen,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const {
    isOpen: isMenuMyUsersOpen,
    onOpen: onOpenMyUsersMenu,
    onClose: onCloseMyUsersMenu,
  } = useDisclosure();
  const {
    isOpen: isMenuMyPointsOpen,
    onOpen: onOpenMyPointsMenu,
    onClose: onCloseMyPointsMenu,
  } = useDisclosure();
  const naviagte = useNavigate();
  const toast = useToast();
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [announcement, setAnnouncement] = useState('');

  const permissions = JSON.parse(localStorage.getItem('state'));
  const canAddNewUser = permissions?.account?.userData?.canCreateUser;
  const canUseBanking = permissions?.account?.userData?.canUseBanking;


  const handleMenuOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyPointsOpen = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMyPointsClose = () => {
    setAnchorE2(null);
  };

  const handleMyUsersOpen = (event) => {
    if (anchorE3 !== event.currentTarget) {
      setAnchorE3(event.currentTarget);
    }
  };

  const handleMyUsersClose = () => {
    setAnchorE3(null);
  };

  const navigateTo = (path) => {
    naviagte(path);
    handleMenuClose();
    handleMyPointsClose();
    handleMyUsersClose();
  };

  const search = async (value) => {
    setSearchValue(value);
    if (!value) {
      setIsSearchResultOpen(false);
    }
    if (value.length >= 3) {
      const res = await searchAPI(value);
      if (res && res.code === 200) {
        setSearchResults(res.response);
        setIsSearchResultOpen(true);
      }
    }
  };

  const getAnnouncement = async () => {
    const res = await getAnnouncementAPI();
    console.log('announcement', res);
    if (res?.status === 200) {
      setAnnouncement(res.data?.response.announcement);
    }
  };
  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    const backDrop = document.querySelector('.MuiBackdrop-invisible');
    // add mouseover event listner
    // if (backDrop)
    //   backDrop.addEventListener('mouseover', () => alert('hi'), false);
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#DDDDDD',
      }}
      className=' '
    >
      <Rules isOpen={isOpen} onClose={onClose} />

      <div
        className='header-top col-md-12'
        style={{
          backgroundColor: '#DDDDDD',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          height: '35px',
        }}
      >
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={() => setIsSidebarOpen((prev) => !prev)}
          variant='outline'
          aria-label='open menu'
          icon={<FiMenu />}
          className='menu-btn'
        />

        <ul className='float-right d-flex align-items-center'>
          <li className='float-left download-apklink'>
            <div>
              <a href='javascript:void(0)' className='rules-link m-r-5'>
                <b>Balance: {userData.balance}</b>
              </a>
            </div>
          </li>
          <li
            className='float-left download-apklink'
            style={{ marginLeft: '40px' }}
          >
            <div>
              <a href='javascript:void(0)' className='rules-link m-r-5'>
                <b>Exposure: {Math.abs(userData.liability)}</b>
              </a>
            </div>
          </li>
          <li
            className='float-left download-apklink'
            style={{ marginLeft: '40px' }}
          >
            <div>
              <a
                href='javascript:void(0)'
                onClick={onOpen}
                className='rules-link m-r-5'
                style={{ display: 'flex' }}
              >
                <img
                  src={PersonIcon}
                  alt='Img'
                  style={{ width: '15px', height: '20px' }}
                />
                <b>
                  Welcome{' '}
                  <span style={{ color: '#21217b' }}>{userData?.username}</span>
                </b>
              </a>
            </div>
          </li>
        </ul>
        <marquee scrollamount={3}>{announcement}</marquee>
      </div>

      <div className='header-bottom col-md-12'>
        <nav className='navbar navbar-expand-md btco-hover-menu'>
          <div className='float-left logo-box'>
            <a href='/' className='logo'>
              <img src={Logo} className='logo-icon' />
            </a>
          </div>
          <button
            type='button'
            data-toggle='collapse'
            data-target='#navbarNavDropdown'
            aria-controls='navbarNavDropdown'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse'>
            <ul className='list-unstyled navbar-nav'>
              <li className='nav-item'>
                <a
                  className={`header-btn ${location.pathname === '/' ? 'active' : ''
                    }`}
                  onClick={() => (window.location.href = '/')}
                >
                  Home
                </a>
              </li>
              <li>
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{
                    mx: 1,
                    border: ' 0.5px solid rgba(255, 255, 255, 0.24)',
                    height: '35px',
                  }}
                />
              </li>

              <li className='nav-item'>
                <Menu isOpen={isMenuOpen} className='dd-mm-hh'>
                  <MenuButton
                    aria-label='Courses'
                    onMouseEnter={onOpenMenu}
                    onMouseLeave={onCloseMenu}
                    className={`header-btn ${location.pathname === '/' ? 'active' : ''
                      }`}
                  >
                    My Account
                  </MenuButton>
                  <MenuList
                    onMouseEnter={onOpenMenu}
                    onMouseLeave={onCloseMenu}
                  >
                    <MenuItem onClick={() => navigateTo('/account')}>
                      Account Statement
                    </MenuItem>
                    {canUseBanking &&
                      <MenuItem onClick={() => navigateTo('/sendMoney')}>
                        Send Money
                      </MenuItem>
                    }
                    <MenuItem onClick={() => navigateTo('/bet_history')}>
                      Bet History
                    </MenuItem>
                    <MenuItem onClick={() => navigateTo('/current')}>
                      Current Bets
                    </MenuItem>
                    <MenuItem onClick={() => navigateTo('/casino-bets')}>
                      Live Casino Bets
                    </MenuItem>
                    <MenuItem onClick={() => navigateTo('/casino-result')}>
                      Casino Results
                    </MenuItem>
                  </MenuList>
                </Menu>
              </li>

              <li>
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{
                    mx: 1,
                    border: ' 0.5px solid rgba(255, 255, 255, 0.24)',
                    height: '35px',
                  }}
                />
              </li>

              {canAddNewUser &&
                <>
                  <li className='nav-item'>
                    <Menu isOpen={isMenuMyUsersOpen}>
                      <MenuButton
                        variant='ghost'
                        mx={1}
                        py={[1, 2, 2]}
                        px={4}
                        borderRadius={5}
                        aria-label='Courses'
                        fontWeight='normal'
                        onMouseEnter={onOpenMyUsersMenu}
                        onMouseLeave={onCloseMyUsersMenu}
                        className={`header-btn ${location.pathname === '/' ? 'active' : ''
                          }`}
                      >
                        My Users
                      </MenuButton>
                      <MenuList
                        onMouseEnter={onOpenMyUsersMenu}
                        onMouseLeave={onCloseMyUsersMenu}
                      >
                        <MenuItem onClick={() => navigateTo('/add-newUser')}>
                          Add New Member
                        </MenuItem>
                        <MenuItem onClick={() => navigateTo('/direct-users')}>
                          Direct User
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </li>

                  <li>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      sx={{
                        mx: 1,
                        border: ' 0.5px solid rgba(255, 255, 255, 0.24)',
                        height: '35px',
                      }}
                    />
                  </li>
                </>}


              {canUseBanking &&
                <>
                  <li className='nav-item'>
                    <Menu isOpen={isMenuMyPointsOpen}>
                      <MenuButton
                        variant='ghost'
                        mx={1}
                        py={[1, 2, 2]}
                        px={4}
                        borderRadius={5}
                        aria-label='Courses'
                        fontWeight='normal'
                        onMouseEnter={onOpenMyPointsMenu}
                        onMouseLeave={onCloseMyPointsMenu}
                        className={`header-btn ${location.pathname === '/' ? 'active' : ''
                          }`}
                      >
                        My Points
                      </MenuButton>
                      <MenuList
                        onMouseEnter={onOpenMyPointsMenu}
                        onMouseLeave={onCloseMyPointsMenu}
                      >

                        <>
                          <MenuItem onClick={() => navigateTo('/deposit')}>
                            Deposit
                          </MenuItem>
                          <MenuItem onClick={() => navigateTo('/withdrawal')}>
                            Withdrawal
                          </MenuItem>
                        </>
                        <MenuItem onClick={() => navigateTo('/bank-account')}>
                          Bank Account
                        </MenuItem>
                        <MenuItem onClick={() => navigateTo('/deposit-history')}>
                          Deposit History
                        </MenuItem>
                        <MenuItem onClick={() => navigateTo('/withdrawal-history')}>
                          Withdrawal History
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </li>

                  <li>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      sx={{
                        mx: 1,
                        border: ' 0.5px solid rgba(255, 255, 255, 0.24)',
                        height: '35px',
                      }}
                    />
                  </li>
                </>}

              <li className='nav-item'>
                <a
                  className={`header-btn ${location.pathname === '/' ? 'active' : ''
                    }`}
                  onClick={() => navigateTo('/dashboard')}
                  style={{
                    fontFamily: "'Inter', 'Helvetica'",
                    fontSize: '20px',
                    fontWeight: 400,
                  }}
                >
                  User DashBoard
                </a>
              </li>

              <li>
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{
                    mx: 1,
                    border: ' 0.5px solid rgba(255, 255, 255, 0.24)',
                    height: '35px',
                  }}
                />
              </li>

              <li className='nav-item'>
                <a
                  className={`header-btn ${location.pathname === '/' ? 'active' : ''
                    }`}
                  style={{
                    fontFamily: "'Inter', 'Helvetica'",
                    fontSize: '20px',
                    fontWeight: 400,
                    cursor: 'pointer',
                  }}
                  aria-controls='dropdown-menu'
                  aria-haspopup='true'
                  onClick={() => {
                    dispatch({ type: 'gameData/logOut' });
                    dispatch({ type: 'accountData/logOut' });
                    localStorage.clear();
                    window.location.href = '/';
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
