import React, { useState } from "react";
import { Stack, Button, Typography } from "@mui/material";
import "../../assets/styles/css/userDetails.scss";
import { AccountStatement } from "../../components/AccountStatement";
import { CurrentBets } from "../../components/Currentbets";

export const UserDetails = () => {
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (label) => () => {
    setSelectedButton((prevLabel) => (prevLabel === label ? null : label));
  };

  const buttons = [
    { label: "Account Statement" },
    { label: "Match" },
    { label: "Session" },
    { label: "Current Bets" },
  ];

  const componentMap = {
    "Account Statement": <AccountStatement />,
    "Current Bets": <CurrentBets />,
    Session: "Coming Soon",
    Match: "Coming Soon",
  };

  return (
    <Stack direction={"column"} gap={1} className="main-container">
      <Stack className="user-details-stack" direction={"row"}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            disableRipple
            variant="contained"
            className="btn-class"
            onClick={handleButtonClick(button.label)}
          >
            <Typography className="btn-text">{button.label}</Typography>
          </Button>
        ))}
      </Stack>
      <Stack>{selectedButton && componentMap[selectedButton]}</Stack>
    </Stack>
  );
};
