import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import LastResults from "../../../components/LastResults";
import "./css/style.css";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
import CasinoVideoHome from "../../../components/CasinoVideoRules/casinoVideoHome";

export default function CasinoQueen() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.casinoQueenOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [plExposure, setPlExposure] = useState(null);
  const dispatch = useDispatch();
  const gameName = "CASINO_QUEEN";
  const CustomButton = ({ text }) => (
    <Button size="sm" my="2" className="casinoButton">
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };
  const getProfit = (selectedEventForBet, isBack = true) => {
    return (
      (isBack ? selectedEventForBet.back1 - 1 : selectedEventForBet.lay1) *
      selectedAmount
    );
  };
  const setEvent = (id, isBack = true) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? "back" : "lay";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey("casinoQueen"));
  }, [dispatch]);

  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      plExposure?.map((item) => {
        data = item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        );
      });

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box h="60vh" mb="2" pos="relative" className="casino-video">
          {odds?.cards && (
            <CardDrawer
              cards={[
                odds?.cards[0],
                odds?.cards[1],
                odds?.cards[2],
                odds?.cards[3],
              ]}
              labels={["TOTAL 0", "TOTAL 1", "TOTAL 2", "TOTAL 3"]}
              multiLevel={true}
            />
          )}
          <div class="market-title">CASINO QUEEN</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3037`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoHome/>
          {/* <CasinoVideoRules
            image={`${ASSETS_URL}dragon-tiger-20%20%20%20%20%20%202-rules.jpg`}
          /> */}
        </Box>
        {/* <div className="card-content redqueen m-t-10"> */}
        {/* <div className="casino-odds-box-wrapper" style={{display:"flex"}}>
          
            <div className="casino-odds-box-container casino-odds-box-container-double">
              <div className="casino-odds-box-bhav">
                <b>Total 0</b>
              </div>
              <div className={`casino-odds-box ${isLocked ? "suspended" : ""}`}>
                <div
                  className="blue-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 0", false);
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {" "}
                    {getData("Total 0").back1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 0")?.exposure}</span>
                </div>
                <div
                  className="lay-border pink-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 0", false);
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {" "}
                    {getData("Total 0").lay1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 0")?.exposure}</span>
                </div>
              </div>
              <span>0</span>
            </div>
            <div className="casino-odds-box-container casino-odds-box-container-double">
              <div className="casino-odds-box-bhav">
                <b>Total 1</b>
              </div>
              <div className={`casino-odds-box ${isLocked ? "suspended" : ""}`}>
                <div
                  className="back-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 1");
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {" "}
                    {getData("Total 1").back1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 1")?.exposure}</span>
                </div>
                <div
                  className="lay-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 1");
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {getData("Total 1").lay1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 1")?.exposure}</span>
                </div>
              </div>
              <span>0</span>
            </div>
            <div className="casino-odds-box-container casino-odds-box-container-double">
              <div className="casino-odds-box-bhav">
                <b>Total 2</b>
              </div>
              <div className={`casino-odds-box ${isLocked ? "suspended" : ""}`}>
                <div
                  className="back-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 2", false);
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {getData("Total 2").back1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 2")?.exposure}</span>
                </div>
                <div
                  className="lay-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 2", false);
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {getData("Total 2").lay1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 2")?.exposure}</span>
                </div>
              </div>
              <span>0</span>
            </div>
            <div className="casino-odds-box-container casino-odds-box-container-double">
              <div className="casino-odds-box-bhav">
                <b>Total 3</b>
              </div>
              <div className={`casino-odds-box ${isLocked ? "suspended" : ""}`}>
                <div className="back-border">
                  <span
                    className="casino-odds-box-odd"
                    onClick={() => {
                      if (!isLocked) {
                        setEvent("Total 3", false);
                      }
                    }}
                  >
                    {getData("Total 3").back1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 3")?.exposure}</span>
                </div>
                <div
                  className="lay-border"
                  onClick={() => {
                    if (!isLocked) {
                      setEvent("Total 3", false);
                    }
                  }}
                >
                  <span className="casino-odds-box-odd">
                    {getData("Total 3").lay1}
                  </span>{" "}
                  <span>{getExposureForEntity("Total 3")?.exposure}</span>
                </div>
              </div>
              <span>0</span>
            </div>
          </div> */}
        {/* </div> */}

        <div
          class="casino-odds-box-wrapper"
          style={{ backgroundColor: "#EEE" }}
        >
          {/* <div> */}
          <div class="casino-odds-box-container">
            <div class="casino-odds-box-bhav">
              <b>Total 0</b>
            </div>
            <div class="casino-odds-box">
              <div
                class={`back-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 0");
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 0").back1}
                </span>
                <span>{getData("Total 0").backSize1}</span>
              </div>
              <div
                class={`lay-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 0", false);
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 0").lay1}
                </span>
                <span>{getData("Total 0").laySize1}</span>
              </div>
            </div>
            <span id="style-ozByJ" class="style-ozByJ">
              {getExposureForEntity("Total 0")?.exposure}
            </span>
          </div>
          <div class="casino-odds-box-container">
            <div class="casino-odds-box-bhav">
              <b>Total 1</b>
            </div>
            <div class="casino-odds-box">
              <div
                class={`back-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 1");
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 1").back1}
                </span>
                <span>{getData("Total 1").backSize1}</span>
              </div>
              <div
                class={`lay-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 1", false);
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 1").lay1}
                </span>
                <span>{getData("Total 1").laySize1}</span>
              </div>
            </div>
            <span id="style-CKPFR" class="style-CKPFR">
              {getExposureForEntity("Total 1")?.exposure}
            </span>
          </div>
          <div class="casino-odds-box-container">
            <div class="casino-odds-box-bhav">
              <b>Total 2</b>
            </div>
            <div class="casino-odds-box">
              <div
                class={`back-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 2");
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 2").back1}
                </span>
                <span>{getData("Total 2").backSize1}</span>
              </div>
              <div
                class={`lay-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 2", false);
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 2").lay1}
                </span>
                <span>{getData("Total 2").laySize1}</span>
              </div>
            </div>
            <span id="style-itUde" class="style-itUde">
              {getExposureForEntity("Total 2")?.exposure}
            </span>
          </div>
          <div class="casino-odds-box-container">
            <div class="casino-odds-box-bhav">
              <b>Total 3</b>
            </div>
            <div class="casino-odds-box">
              <div
                class={`back-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 3");
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 3").back1}
                </span>
                <span>{getData("Total 3").backSize1}</span>
              </div>
              <div
                class={`lay-border ${isLocked ? "suspended" : ""}`}
                onClick={() => {
                  if (!isLocked) {
                    setEvent("Total 3", false);
                  }
                }}
              >
                <span class="casino-odds-box-odd">
                  {getData("Total 3").lay1}
                </span>
                <span>{getData("Total 3").laySize1}</span>
              </div>
            </div>
            <span id="style-6xzSd" class="style-6xzSd">
              {getExposureForEntity("Total 3")?.exposure}
            </span>
          </div>
          {/* </div> */}
          <div className="col-md-12 m-t-10 text-right">
            <span className="m-r-10">
              <b>Min:</b>
              <span>100 | </span>
            </span>
            <span>
              <b>Max: </b>
              <span>100000</span>
            </span>
          </div>
        </div>
        <marquee
          data-v-ec4526dc=""
          scrollamount="3"
          class="casino-remark m-b-10"
        >
          This is 21 cards game 2,3,4,5,6 x 4 =20 and 1 Queen. Minimum total 10
          or queen is required to win.
        </marquee>

        {/* <Grid gap={2} templateColumns="repeat(4,1fr)" bgColor={"#3C444B85"}>
          <GridItem onClick={() => setEvent("Tie")} p="2" textAlign="center">
            <Text>Total 0</Text>
            <CustomButton />
            <Text>0</Text>
          </GridItem>
          <GridItem onClick={() => setEvent("Tie")} p="2" textAlign="center">
            <Text>Total 1</Text>
            <CustomButton />
            <Text>0</Text>
          </GridItem>
          <GridItem
            onClick={() => setEvent("Tiger Red")}
            p="2"
            textAlign="center"
          >
            <Text>Total 2</Text>
            <CustomButton />
            <Text>0</Text>
          </GridItem>
          <GridItem onClick={() => setEvent("Pair")} p="2" textAlign="center">
            <Text>Total 3</Text>
            <CustomButton />
            <Text>0</Text>
          </GridItem>
        </Grid> */}
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
