import { AXIOS_INSTANCE } from "../../service";
import { BASE_URL } from "../../utils/constants";

export const verifyMFACode = async (otp, username, isDisabled = "") => {
  try {
    const res = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/verify-otp?otp=${otp}&userName=${username}${isDisabled}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const resendOTP = async (username) => {
  try {
    const res = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/resend-otp?userName=${username}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
