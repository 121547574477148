import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import { placeCasinoBetAPI } from '../../../service/casino';
import PlaceBet from '../../game/PlaceBet';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { ASSETS_URL } from '../../../utils/constants';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';

export default function TeenPatti1Day() {
  const odds_ = useSelector((state) => state.gameData.teenOdds);
  //useMemo
  const [plExposure, setPlExposure] = useState(null);
  const odds = useMemo(() => odds_, [odds_]);
  const gameName = 'TEEN';
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();

  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={'md'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='casinoButton'
    >
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const CustomButtonPink = ({ text, ml, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      ml={ml}
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='casinoButton-pink'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  useEffect(() => {
    dispatch(setGameKey('teen'));
  }, [dispatch]);

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id, param = 'back1') => {
    if (param === 'back1') {
      if (getData(id).back1 == 0) {
        return;
      }
    } else {
      if (getData(id).lay1 == 0) {
        return;
      }
    }
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet[param];
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = param?.includes('back') ? 'back' : 'lay';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }
      return data ? data : null;
    }
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box h='60vh' mb='2' pos='relative' className='casino-video'>
          {odds.cards && (
            <CardDrawer
              multiLevel={true}
              cards={[odds?.cards[0], odds?.cards[1]]}
              labels={['PLAYER A', 'PLAYER B']}
            />
          )}
          <div class='market-title'>1 Day TeenPatti</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3031`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            image={`${ASSETS_URL}1 day teen pattip-rules.jpg`}
          />
        </Box>

        <div className='m-t-0'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='box-6'></th>
                <th className='back text-center box-2'>BACK</th>
                <th
                  className='lay text-center box-2'
                  style={{ color: 'black' }}
                >
                  LAY
                </th>
              </tr>
            </thead>
            <tbody>
              <tr data-title='ACTIVE' className=''>
                <td className='box-6'>
                  <p className='m-b-0'>Player A</p>
                  <p className='m-b-0'>
                    <span className='font-weight-bold'>
                      {getExposureForEntity('Player A')?.exposure}
                    </span>{' '}
                  </p>
                </td>
                <td
                  className={`back text-center box-2 ${
                    isLocked ? 'suspended' : ''
                  }`}
                  onClick={() => setEvent('Player A')}
                >
                  <span className='d-block text-bold'>
                    <b> {getData('Player A').back1}</b>
                  </span>{' '}
                  <span className='d-block'>
                    {getData('Player A').backSize1}
                  </span>
                </td>
                <td
                  className={`lay text-center box-2 ${
                    isLocked ? 'suspended' : ''
                  }`}
                  onClick={() => setEvent('Player A', 'lay1')}
                >
                  <span className='d-block text-bold'>
                    <b>{getData('Player A').lay1}</b>
                  </span>{' '}
                  <span className='d-block'>
                    {getData('Player A').laySize1}
                  </span>
                </td>
              </tr>
              <tr data-title='ACTIVE' className=''>
                <td className='box-6'>
                  <p className='m-b-0'>Player B</p>
                  <p className='m-b-0'>
                    <span className='font-weight-bold'>
                      {getExposureForEntity('Player B')?.exposure}
                    </span>{' '}
                  </p>
                </td>
                <td
                  className={`back text-center box-2 ${
                    isLocked ? 'suspended' : ''
                  }`}
                  onClick={() => setEvent('Player B')}
                >
                  <span className='d-block text-bold'>
                    <b> {getData('Player B').back1}</b>
                  </span>{' '}
                  <span className='d-block'>
                    {getData('Player B').backSize1}
                  </span>
                </td>
                <td
                  className={`lay text-center box-2 ${
                    isLocked ? 'suspended' : ''
                  }`}
                  onClick={() => setEvent('Player B', 'lay1')}
                >
                  <span className='d-block text-bold'>
                    <b>{getData('Player B').lay1}</b>
                  </span>{' '}
                  <span className='d-block'>
                    {getData('Player B').laySize1}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='casino-remark mt-1'>
          <div className='remark-icon'>
            <img
              src='https://png.pngtree.com/png-vector/20191129/ourlarge/pngtree-chat-icon-blue-vector--discussion-icon-png-image_2047530.jpg'
              alt='image'
            />
          </div>{' '}
          <marquee>TeenPatti 1 Day start...</marquee>
        </div>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game='casino' eventId={gameName} />
    </Box>
  );
}
