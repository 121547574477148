import React from 'react';
import { useState } from 'react';

import { useEffect } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import AddAccount from './AddAccount';
import { paymentDetailsApi } from '../../service/WalletService';
import { addUserBankData } from '../../redux/features/paymentSlice';
import { useDispatch } from 'react-redux';

const BankAccount = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const [showDrop, setShowDrop] = useState('');
  const [bankDetails, setBankDetails] = useState([]);
  const [gpay, setGpay] = useState([]);
  const [paytm, setPaytm] = useState([]);
  const [phonePe, setPhonePe] = useState([]);
  const [usdt, setUsdt] = useState([]);

  const handleAddBank = () => {
    setShow(!show);
  };
  const close = () => {
    setShow(false);
  };
  const handleBankDropDown = (dropType) => {
    setShowDrop(dropType);
  };
  const paymentdetails = async () => {
    try {
      const res = await paymentDetailsApi();

      if (res?.status == 200) {
        setBankDetails(res?.data?.data?.UserBankDetails);
        dispatch(addUserBankData(res?.data?.data?.UserBankDetails));
        //setBankDetails(res?.data?.data?.UserBankDetails);
        setGpay(res?.data?.data?.UserUPIDetails?.gpay);
        setPaytm(res?.data?.data?.UserUPIDetails?.paytm);
        setPhonePe(res?.data?.data?.UserUPIDetails?.phonepe);
        setUsdt(res?.data?.data?.UserUPIDetails?.usdt);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    paymentdetails();
    return () => {
      setBankDetails([]);
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <Box w={'100%'} className='bank-account'>
      <Flex align={'center'} justify={'space-between'} w={'100%'}>
        <Box></Box>
        <Text fontSize={'18px'} fontWeight={600}>
          Payment Account Details
        </Text>
        <Button p='10px' onClick={onOpen}>
          Add Bank Account
        </Button>
      </Flex>
      <Accordion mt='25px' defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton px='10px'>
              <Box as='span' flex='1' textAlign='left'>
                <Flex align={'center'} gap={'15px'}>
                  {/* <Image
                    height={'50px'}
                    d='none'
                    src='https://hotsports.com/img/payment_meThod/bt.png'
                  />{' '} */}
                  Bank Transfer
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Bank Name</Th>
                  <Th>Account Number</Th>
                  <Th>IFSC </Th>
                </Tr>
              </Thead>

              <Tbody>
                {bankDetails &&
                  bankDetails?.map((data, index) => (
                    <Tr key={index}>
                      <Td>{data?.bankName}</Td>
                      <Td>
                        {data?.accountNumber}
                      </Td>
                      <Td>
                        {data?.ifscCode}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton px='10px'>
              <Box as='span' flex='1' textAlign='left'>
                <Flex align={'center'} gap={'15px'}>
                  {/* <Image
                    height={'50px'}
                    d='none'
                    src='https://hotsports.com/img/payment_meThod/f-paytm.png'
                  />{' '} */}
                  Paytm
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {paytm && paytm?.length > 0 ? (
              <>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>User Name</Th>
                      <Th>UPI ID</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paytm.map((data, index) => (
                      <Tr>
                        <Td>{data.userName}</Td>
                        <Td>{data.value}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </>
            ) : (
              <>
                <p style={{ fontSize: 'small', textAlign: 'center' }}>
                  You have not added any Paytm account yet. Please add your
                  Paytm to withdraw your funds.
                </p>{' '}
                <Flex justify={'center'} align={'center'} p='20px'>
                  <Button
                    px='10px'
                    py='5px'
                    className='green-button'
                    onClick={onOpen}
                  >
                    Add Bank
                  </Button>
                  {/* <AddBank
                            getPaymenTdetails={paymenTdetails}
                            show={show}
                            onClose={close}
                          /> */}
                </Flex>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton px='10px'>
              <Box as='span' flex='1' textAlign='left'>
                <Flex align={'center'} gap={'15px'}>
                  {/* <Image
                    height={'50px'}
                    d='none'
                    src='https://hotsports.com/img/payment_meThod/googlepay.webp'
                  />{' '} */}
                  GPay
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {gpay && gpay?.length > 0 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th>User Name</Th>
                    <Th>UPI ID</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {gpay.map((data, index) => (
                    <Tr>
                      <Td>{data.userName}</Td>
                      <Td>{data.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <>
                <p style={{ fontSize: 'small', textAlign: 'center' }}>
                  You have not added any Gpay account yet. Please add your Gpay
                  to withdraw your funds.
                </p>{' '}
                <Flex justify={'center'} align={'center'} p='20px'>
                  <Button
                    px='10px'
                    py='5px'
                    className='green-button'
                    onClick={onOpen}
                  >
                    Add Bank
                  </Button>
                  {/* <AddBank
                            getPaymenTdetails={paymenTdetails}
                            show={show}
                            onClose={close}
                          /> */}
                </Flex>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton px='10px'>
              <Box as='span' flex='1' textAlign='left'>
                <Flex align={'center'} gap={'15px'}>
                  {/* <Image
                    height={'50px'}
                    d='none'
                    src='https://hotsports.com/img/payment_meThod/phonepe.webp'
                  />{' '} */}
                  PhonePe
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {phonePe && phonePe?.length > 0 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th>User Name</Th>
                    <Th>UPI ID</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {phonePe.map((data, index) => (
                    <Tr>
                      <Td>{data.userName}</Td>
                      <Td>{data.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <>
                <p style={{ fontSize: 'small', textAlign: 'center' }}>
                  You have not added any PhonePe account yet. Please add your
                  PhonePe to withdraw your funds.
                </p>{' '}
                <Flex justify={'center'} align={'center'} p='20px'>
                  <Button
                    px='10px'
                    py='5px'
                    className='green-button'
                    onClick={onOpen}
                  >
                    Add Bank
                  </Button>
                  {/* <AddBank
                            getPaymenTdetails={paymenTdetails}
                            show={show}
                            onClose={close}
                          /> */}
                </Flex>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton px='10px'>
              <Box as='span' flex='1' textAlign='left'>
                <Flex align={'center'} gap={'15px'}>
                  {/* <Image
                    height={'50px'}
                    d='none'
                    src='https://hotsports.com/img/payment_meThod/phonepe.webp'
                  />{' '} */}
                  USDT
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {usdt && usdt?.length > 0 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th>USDT Address</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usdt.map((data, index) => (
                    <Tr>
                      <Td>{data.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <>
                <p style={{ fontSize: 'small', textAlign: 'center' }}>
                  You have not added any USDT account yet. Please add your USDT
                  to withdraw your funds.
                </p>{' '}
                <Flex justify={'center'} align={'center'} p='20px'>
                  <Button
                    px='10px'
                    py='5px'
                    className='green-button'
                    onClick={onOpen}
                  >
                    Add Bank
                  </Button>
                  {/* <AddBank
                            getPaymenTdetails={paymenTdetails}
                            show={show}
                            onClose={close}
                          /> */}
                </Flex>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Modal className="bank-account-modal" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalBody>
            <ModalOverlay bg='rgba(0,0,0,.5)' />
            <Box
              className='modal-box-reset'
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: ['90vw', null, '33.54vw'], 
                height: 'auto',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0px 0px 96px rgba(0, 0, 0, 0.1)',
                padding: '8px',
                overflow: 'auto',
              }}
            >
              <CloseButton
                onClick={onClose}
                pos={'absolute'}
                right={'10px'}
                top={'10px'}
              />
              <Flex justify={'center'}>
                <Text fontSize={'20px'}>Add Account</Text>
              </Flex>
              <AddAccount
                onClose={onClose}
                getPaymentDetails={paymentdetails}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BankAccount;
