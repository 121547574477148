import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';
import PlaceBet from '../../game/PlaceBet';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { ASSETS_URL } from '../../../utils/constants';
import LastResults from '../../../components/LastResults';
import Popover from '../../../components/Popover';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function Cards32B() {
  const dispatch = useDispatch();
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.card32euOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const gameName = 'CARD32EU';
  const [plExposure, setPlExposure] = useState(null);
  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='pattiButton'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const CustomButtonPink = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='casinoButton-pink'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  useEffect(() => {
    dispatch(setGameKey('card32eu'));
  }, [dispatch]);

  const getProfit = (size) => {
    return (size - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    if (isBack) {
      if (getData(id).back1 == 0) return;
    } else {
      if (getData(id).lay1 == 0) return;
    }

    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(
      isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1
    );
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? 'back' : 'lay';
    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
    let transformedData = [];

    if (res && res?.code == 200) {
      res.response.map((item) =>
        item.casinoPLExposure.map((item) => transformedData.push(item))
      );
      setPlExposure(transformedData);
    }
  };
  const getExposureForEntity = (selectionName) => {
    if (plExposure) {
      let data = plExposure?.find(
        (exposure) => exposure.selectionName == selectionName
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
      className='card-game-box'
    >
      <Box mt='1' w={{ base: '100%', sm: '100%', lg: '70%' }} mr='1'>
        <Box className='casino-video' mb='2' pos='relative'>
          {odds.cards && (
            <CardDrawer
              cards={[
                odds?.cards[0],
                odds?.cards[1],
                odds?.cards[2],
                odds?.cards[3],
              ]}
              labels={['TOTAL 8', 'TOTAL 9', 'TOTAL 10', 'TOTAL 11']}
              multiLevel={true}
              singleCard
            />
          )}
          <div className='market-title'>32 Card B</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3034`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}32_cards B_rule.jpg`} />
        </Box>
        <div className='row row5'>
          <div className='table-responsive col-md-6 main-market m-b-10'>
            <div className='live-poker'>
              <table className='table coupon-table table table-bordered '>
                <thead>
                  <tr>
                    <th className='w-6'>
                      <div className='fas m-l-10'>
                        <Popover>
                          <div>
                            <p>Min: 100</p>
                            <p>Max: 500000</p>
                          </div>
                        </Popover>
                      </div>
                    </th>
                    <th className=' back w-2'>BACK</th>
                    <th className=' lay w-2'>LAY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 8</b>
                      </p>
                      <div>
                        <div className='ubook'>
                          <b>{getExposureForEntity('Player 8')?.exposure}</b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 8')}
                        className='back'
                      >
                        <span className='odd'>{getData('Player 8').back1}</span>{' '}
                        <br />
                        <span>{getData('Player 8').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 8', false)}
                        className='lay'
                      >
                        <span className='odd'>{getData('Player 8').lay1}</span>{' '}
                        <br />
                        <span>{getData('Player 8').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 9</b>
                      </p>
                      <div>
                        <div className='ubook'>
                          <b>{getExposureForEntity('Player 9')?.exposure}</b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 9')}
                        className='back'
                      >
                        <span className='odd'>
                          {' '}
                          {getData('Player 9').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('Player 9').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 9', false)}
                        className='lay'
                      >
                        <span className='odd'>{getData('Player 9').lay1}</span>{' '}
                        <br />
                        <span>{getData('Player 9').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 10</b>
                      </p>
                      <div>
                        <div className='ubook'>
                          <b>{getExposureForEntity('Player 10')?.exposure}</b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 10')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 10').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('Player 10').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 10', false)}
                        className='lay'
                      >
                        <span className='odd'>{getData('Player 10').lay1}</span>{' '}
                        <br />
                        <span>{getData('Player 10').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 11</b>
                      </p>
                      <div>
                        <div className='ubook'>
                          <b>{getExposureForEntity('Player 11')?.exposure}</b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 11')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 11').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('Player 11').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 11', false)}
                        className='lay'
                      >
                        <span className='odd'>{getData('Player 11').lay1}</span>{' '}
                        <br />
                        <span>{getData('Player 11').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='table-responsive col-md-6 main-market m-b-10'>
            <div className='live-poker'>
              <table className='table coupon-table table table-bordered '>
                <thead>
                  <tr>
                    <th className='w-6'>
                      <div className='fas m-l-10'>
                        <Popover>
                          <div>
                            <p>Min: 100</p>
                            <p>Max: 100000</p>
                          </div>
                        </Popover>
                      </div>
                    </th>
                    <th className=' back w-2'>Even</th>
                    <th className=' back w-2'>Odd</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 8</b>
                      </p>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => {
                          if (getData('Player 8 Odd').back1 == 0) return;
                          setEvent('Player 8 Odd');
                        }}
                        className='back'
                      >
                        <span className='odd'>
                          {' '}
                          {getData('Player 8 Odd').back1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {' '}
                              {getExposureForEntity('Player 8 Odd')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => {
                          if (getData('Player 8 Even').lay1 == 0) return;
                          setEvent('Player 8 Even');
                        }}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 8 Even').lay1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {' '}
                              {getExposureForEntity('Player 8 Even')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 9</b>
                      </p>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 9 Odd')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 9 Odd').back1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {' '}
                              {getExposureForEntity('Player 9 Odd')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 9 Even')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 9 Even').lay1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {getExposureForEntity('Player 9 Even')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 10</b>
                      </p>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 10 Odd')}
                        className='back'
                      >
                        <span className='odd'>
                          {' '}
                          {getData('Player 10 Odd').back1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {' '}
                              {getExposureForEntity('Player 10 Odd')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 10 Even')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 10 Even').lay1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {' '}
                              {getExposureForEntity('Player 10 Even')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Player 11</b>
                      </p>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 11 Odd')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 11 Odd').back1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {getExposureForEntity('Player 11 Odd')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Player 11 Even')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Player 11 Even').lay1}
                        </span>
                        <br />
                        <div>
                          <div className='ubook text-danger'>
                            <b>
                              {getExposureForEntity('Player 11 Even')?.exposure}
                            </b>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row row5 m-t-10'>
          <div className='table-responsive col-md-6 main-market m-b-10'>
            <div className='live-poker'>
              <table className='table coupon-table table table-bordered '>
                <thead>
                  <tr>
                    <div className='fas m-l-10'>
                      <Popover>
                        <div>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                    <th className=' back w-2'>BACK</th>
                    <th className=' lay w-2'>LAY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Any Three Card Black</b>
                      </p>
                      <div>
                        <div className='ubook text-danger'>
                          <b>
                            {
                              getExposureForEntity('Any Three Card Black')
                                ?.exposure
                            }
                          </b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Any Three Card Black')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Any Three Card Black').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('Any Three Card Black').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Any Three Card Black', false)}
                        className='lay'
                      >
                        <span className='odd'>
                          {getData('Any Three Card Black').lay1}
                        </span>{' '}
                        <br />
                        <span>{getData('AnyThree Card Black').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Any Three Card Red</b>
                      </p>
                      <div>
                        <div className='ubook text-danger'>
                          <b>
                            {
                              getExposureForEntity('Any Three Card Red')
                                ?.exposure
                            }
                          </b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Any Three Card Red')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Any Three Card Red').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('Any Three Card Red').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Any Three Card Red', false)}
                        className='lay'
                      >
                        <span className='odd'>
                          {getData('Any Three Card Red').lay1}
                        </span>{' '}
                        <br />
                        <span>{getData('Any Three Card Red').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>Two Black Two Red</b>
                      </p>
                      <div>
                        <div className='ubook text-danger'>
                          <b>
                            {
                              getExposureForEntity('Two Black Two Red')
                                ?.exposure
                            }
                          </b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Two Black Two Red')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('Two Black Two Red').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('Two Black Two Red').backSize1}</span>
                      </button>
                    </td>
                    <td className={`lay w-2 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('Two Black Two Red', false)}
                        className='lay'
                      >
                        <span className='odd'>
                          {getData('Two Black Two Red').lay1}
                        </span>{' '}
                        <br />
                        <span>{getData('Two Black Two Red').laySize1}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='table-responsive col-md-6 main-market m-b-10 card-total-block'>
            <div className='live-poker'>
              <table className='table coupon-table table table-bordered '>
                <thead>
                  <tr>
                    <div className='fas m-l-10'>
                      <Popover>
                        <div>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                    <th className=' w-4'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>8 &amp; 9 Total</b>
                      </p>
                      <div>
                        <div className='ubook text-danger'>
                          <b>
                            {' '}
                            {getExposureForEntity('8 & 9 Total')?.exposure}
                          </b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-4 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('8 & 9 Total')}
                        className='back'
                      >
                        <span className='odd'>
                          {getData('8 & 9 Total').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('8 & 9 Total').backSize1}</span>
                      </button>
                    </td>
                  </tr>
                  <tr className='bet-info'>
                    <td className='w-6'>
                      <p className='m-b-0'>
                        <b>10 &amp; 11 Total</b>
                      </p>
                      <div>
                        <div className='ubook text-danger'>
                          <b>
                            {' '}
                            {getExposureForEntity('10 & 11 Total')?.exposure}
                          </b>
                        </div>
                      </div>
                    </td>
                    <td className={`back w-4 ${isLocked ? 'suspendedtd' : ''}`}>
                      <button
                        onClick={() => setEvent('10 & 11 Total')}
                        className='back'
                      >
                        <span className='odd'>
                          {' '}
                          {getData('10 & 11 Total').back1}
                        </span>{' '}
                        <br />
                        <span>{getData('10 & 11 Total').backSize1}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class='row row5 card32worli card32eu casino-32-worli m-t-10'>
          <div class='col-12'>
            <div class='table-responsive'>
              <table class='table table-bordered'>
                <tbody className={` ${isLocked ? 'suspendedtd' : ''}`}>
                  <tr>
                    <th colspan='5' class='text-center card-odds'>
                      <span>
                        <b>9.50</b>
                      </span>
                      <div class='info-block float-right'>
                        <div className='fas m-l-10'>
                          <Popover>
                            <div>
                              <p>Min: 100</p>
                              <p>Max: 30000</p>
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class='back'>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <td
                        class='text-center bet-action'
                        onClick={() => setEvent(`Single ${item}`)}
                      >
                        <span class='d-block card-number'>{item}</span>
                        <div>
                          <div class='ubook text-danger'>
                            <b>
                              {getExposureForEntity(`Single ${item}`)?.exposure}
                            </b>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr class='back'>
                    {[6, 7, 8, 9, 0].map((item) => (
                      <td
                        class='text-center bet-action'
                        onClick={() => setEvent(`Single ${item}`)}
                      >
                        <span class='d-block card-number'>{item}</span>
                        <div>
                          <div class='ubook text-danger'>
                            <b>
                              {getExposureForEntity(`Single ${item}`)?.exposure}
                            </b>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <marquee scrollamount='3' id='mar' class='casino-remark m-b-10'>
          Hi.
        </marquee>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game='casino' eventId={gameName} />
    </Box>
  );
}
