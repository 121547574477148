export const options = [
  { value: 'ALL', label: 'All' },
  { value: 'BALANCE_REPORT', label: 'Deposit/Withdrawal Report' },
  { value: 'GAME_REPORT', label: 'Game Report' },
];
export const sportsOptions = [
  { value: 'CRICKET', label: 'Cricket' },
  { value: 'SOCCER', label: 'Football' },
  { value: 'TENNIS', label: 'Tennis' },
];
export const casinosOptions = [
  {
    label: 'Select Type',
    value: 'none',
  },
  {
    label: 'Dragon Tiger-1 2020',
    image: 'dt20.jpg',
    value: 'DRAGON_TIGER_20',
  },
  {
    label: 'Dragon Tiger 2020 2',
    image: 'dt202.jpg',
    value: 'DRAGON_TIGER_20_2',
  },
  {
    label: 'Dragon Tiger 1 Day',
    image: 'dt6.jpg',
    value: 'DRAGON_TIGER_6',
  },
  {
    label: 'Dragon Tiger Lion',
    image: 'dtl20.jpg',
    value: 'DRAGON_TIGER_LION_20',
  },
  {
    label: 'Teen Patti 2020',
    image: 'teen20.jpg',
    value: 'TEEN_PATTI_20',
  },

  {
    label: 'Teen Patti Test',
    image: 'teen9.jpg',
    value: 'TEEN_9',
  },
  {
    label: 'Teen Patti Open',
    image: 'teen8.jpg',
    value: 'TEEN_8',
  },
  {
    label: 'Casino Queen',
    image: 'queen.jpg',
    value: 'CASINO_QUEEN',
  },
  {
    label: '32 Cards A',
    image: 'card32.jpg',
    value: 'CARD_32',
  },
  {
    label: '32 Cards B',
    image: 'card32eu.jpg',
    value: 'CARD32EU',
  },

  {
    label: 'Poker 20-20',
    image: 'poker20 20.jpg',
    value: 'POKER_20',
  },
  {
    label: 'Lucky 7-A',
    image: 'lucky7.jpg',
    value: 'LUCKY7',
  },
  {
    label: 'Lucky 7-B',
    image: 'lucky7eu.jpg',
    value: 'LUCKY7EU',
  },
  {
    label: 'Andar Bahar',
    image: 'andar-bahar.jpg',
    value: 'AB_20',
  },
  {
    label: 'Andar Bahar 2',
    image: 'andar-bahar2.jpg',
    value: 'ABJ',
  },

  {
    label: 'Bollywood Casino',
    image: 'btable.jpg',
    value: 'BOLLYWOOD_TABLE',
  },
  {
    label: 'Amar Akbar Anthony',
    image: 'aaa.jpg',
    value: 'AAA',
  },
  {
    label: 'Instant Worli',
    image: 'instant worli.jpg',
    value: 'WORLI2',
  },

  {
    label: 'Casino War',
    image: 'casino-war.jpg',
    value: 'CASINO_WAR',
  },
  {
    label: 'Race 20',
    image: 'race-20.png',
    value: 'RACE20',
  },
  {
    label: '5-5 Cricket',
    image: 'five cricket.jpg',
    value: '/live-casino/cricketV3',
  },
  {
    label: 'Cricket 20-20',
    image: 'cmatch20.jpg',
    value: '/live-casino/cmatch20',
  },
  {
    label: 'Baccart',
    image: 'baccarat.jpg',
    value: 'BACCARAT',
  },
  {
    label: 'Baccart 2',
    image: 'baccarat2.jpg',
    link: 'BACCARAT2',
  },
  {
    label: 'Worli Matka',
    image: 'worli.jpg',
    link: 'WORLI2',
  },
  {
    label: 'Super Over',
    image: 'superover.png',
    link: 'SUPEROVER',
  },
  {
    label: '1-Day Poker',
    image: 'poker 1 day.jpg',
    link: 'POKER_1_DAY',
  },
  {
    label: '1 Day Teen Patti',
    image: 'teen.jpg',
    link: 'TEEN',
  },
  {
    label: 'Poker 6 Players',
    image: 'poker6.jpg',
    link: 'POKER_9',
  },
  {
    label: '3 Cards Judgement',
    image: '3 card judgement.jpg',
    link: 'THREE_CARD',
  },
  {
    label: 'Casino Meter',
    image: 'casino meter.jpg',
    link: 'CASINO_WAR',
  },
];
