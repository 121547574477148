import React from 'react';

const FavIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='25'
      viewBox='0 0 27 25'
      fill='none'
    >
      <path
        d='M12.7399 1.56648C12.8854 1.11863 13.519 1.11863 13.6645 1.56648L15.6178 7.57797C15.9246 8.52219 16.8044 9.16147 17.7973 9.16147H24.1182C24.589 9.16147 24.7848 9.76407 24.4039 10.0409L19.2903 13.7562C18.4871 14.3397 18.151 15.3741 18.4578 16.3183L20.411 22.3298C20.5565 22.7777 20.0439 23.1501 19.6629 22.8733L14.5493 19.158C13.746 18.5744 12.6584 18.5744 11.8552 19.158L6.74154 22.8733C6.36057 23.1501 5.84798 22.7777 5.9935 22.3298L7.94675 16.3183C8.25354 15.3741 7.91744 14.3397 7.11425 13.7562L2.00057 10.0409C1.61959 9.76407 1.8154 9.16147 2.2863 9.16147H8.60715C9.59996 9.16147 10.4799 8.52219 10.7867 7.57797L12.7399 1.56648Z'
        stroke='#DFC548'
        strokeWidth='1.3'
      />
    </svg>
  );
};

export default FavIcon;
