import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";

import CustomInput from "../../components/CustomInput";
import { Encrypt, buttonColors } from "../../utils/constants";
import CasinoResultModal from "../../components/CasinoResultModal/CasinoResultModal";
import { changePasswordAPI } from "../../service/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

export default function ChangePasswordPage() {
  const toast = useToast();
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = async () => {
    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      toast({
        description: "Please fill all the fields",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        description: "newPassword and confirm Password does not match",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    if (newPassword === oldPassword) {
      toast({
        description: "New Password and old Password cannot be same",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        description:
          "newPassword must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    var res = await changePasswordAPI(
      {
        newPassword: Encrypt(newPassword),
        password: Encrypt(oldPassword),
        resetRequired: false,
      },
      userData.token
    );
    if (res.code === 200) {
      toast({
        title: "Success",
        description: "New Password changed successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 500);
    } else {
      toast({
        description: "Wrong Password",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
  };

  console.log(oldPassword, newPassword, "password")

  return (
    <Box
      p={{ base: 0, md: 2 }}
      bg={{ base: "transparent", md: "#fff" }}
      textColor="#A6A6A6"
      pt={{ base: "60px", md: "0" }}
      className="change-password-box setting-page-main"
    >
      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        background="#ae4600bd"
        padding={2}
        marginTop="-5px"
      >
        <Text textColor="white" fontSize="xl" color="#fff !important">
          Change Password
        </Text>
      </Box>

      <Box mt={5} className="col-md-6 change-button-value-box">
        <CustomInput
          label="Oooold Password"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          bg={"#ddd"}
          className="form-group"
        />
        <CustomInput
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          bg={"#ddd"}
          className="form-group"
        />
        <CustomInput
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          bg={"#ddd"}
          className="form-group"
        />
        <Button
          className="blue-btn"
          onClick={changePassword}
          mt="10px"
          w={"100%"}
          bg={buttonColors}
        >
          Change Password
        </Button>
      </Box>
    </Box>
  );
}
