import { Box, Button, Input, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordAPI } from '../service/AuthService';
import { Encrypt } from '../utils/constants';
import cross from '../../src/assets/images/icons/cross.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function ChangePassword({ onClick }) {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const navigateTo = (path) => {
    naviagte(path);
  };

  const changePassword = async () => {
    if (password === '' || confirmPassword === '' || oldPassword === '') {
      toast({
        description: 'Please fill all the fields',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        description: 'Password and confirm password does not match',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (password === oldPassword) {
      toast({
        description: 'New password and old password cannot be same',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      toast({
        description:
          'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    const res = await changePasswordAPI(
      {
        newPassword: Encrypt(password),
        password: Encrypt(oldPassword),
        resetRequired: false,
        uid: userData.id,
      },
      userData.token
    );
    console.log(res, 'res');
    if (res && res?.data?.code === 200) {
      toast({
        title: 'Success',
        description: 'Password changed successfully',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      localStorage.clear();
      window.location.href = '/';
    } else {
      toast({
        description:
          res?.response?.data?.error?.description || 'Somthing went wrong!',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  console.log(oldPassword, password, "password")

  return (
    <Box p='2'>
      <Box
        p='2'
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
        w='100%'
        gap={'100px'}
        mb='5'
      >
        <Text
          color='#102a6d'
          fontFamily={"'Inter',Helvetica"}
          fontSize={'18px'}
          fontWeight={600}
        >
          Change Password
        </Text>
        {/* <img
          src={cross}
          alt=""
          width={"16px"}
          height={"16px"}
          color="#4E4E4E"
          onClick={onClick}
          style={{
            cursor:'pointer'
          }}
        ></img> */}
      </Box>

      <Box w='40%' gap={'16px'}>
        <Box gap={'8px'}>
          <Text
            color='#4E4E4E'
            fontFamily={"'Inter',Helvetica"}
            fontSize={'15px'}
            fontWeight={500}
          >
            Old Password
          </Text>
          <Input
            style={{
              width: '250%',
              height: '50px',
              border: ' 1px solid #C9C9C9',
              borderRadius: '10px',
              backgroundColor: '#EEEEEE',
            }}
            type='password'
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            mb='4'
          />
        </Box>

        <Box gap={'8px'}>
          <Text
            color='#4E4E4E'
            fontFamily={"'Inter',Helvetica"}
            fontSize={'15px'}
            fontWeight={500}
          >
            New Password
          </Text>
          <Input
            style={{
              width: '250%',
              height: '50px',
              border: ' 1px solid #C9C9C9',
              borderRadius: '10px',
              backgroundColor: '#EEEEEE',
            }}
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            mb='4'
          />
        </Box>

        <Box gap='8px'>
          <Text
            color='#4E4E4E'
            fontFamily={"'Inter',Helvetica"}
            fontSize={'15px'}
            fontWeight={500}
          >
            Confirm Password
          </Text>
          <Input
            style={{
              width: '250%',
              height: '50px',
              border: ' 1px solid #C9C9C9',
              borderRadius: '10px',
              backgroundColor: '#EEEEEE',
            }}
            type='password'
            value={confirmPassword}
            mb='4'
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Box>
      </Box>

      <Box
        display={'flex'}
        justifyContent={'center'}
        mt={'10px'}
        alignContent={'center'}
      >
        <Button
          bg='#102A6D'
          onClick={changePassword}
          width={'100%'}
          borderRadius={'10px'}
          height={'50px'}
        >
          <Text
            fontFamily={"'Inter',Helvetica"}
            fontSize={'16px'}
            fontWeight={600}
            color={'#fff'}
          >
            Change Password
          </Text>
        </Button>
      </Box>
    </Box>
  );
}
