import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { SearchWithText } from '../shared/SearchWithText';
import { GenericTable } from '../shared/GenericTable';
import '../../assets/styles/css/current.scss';
import { getCurrentBets } from '../../service/AuthService';

export const CasinotBets = () => {
  const [currentBetsData, setCurrentBetsData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);

  useEffect(() => {
    getCurrentBetsHandler();
  }, []);

  const getCurrentBetsHandler = async () => {
    const payLoad = {
      pageNumber: 0,
      pageSize: 100,
      sports: false,
    };
    const response = await getCurrentBets(payLoad);

    if (response && response?.code === 200) {
      const currentBetsTableRow =
        response?.response?.unsettledBets?.content?.map((data, index) => ({
          No: index + 1,
          Sports: data.event_type || '',
          'Event Name': data.event_name || '',
          'Market Name': data.marketName || '',
          Nation: data.runner_name || '',
          'User Rate': data.lay_rate || '',
          Amount: data.amount || '',
          'Place Date': new Date(data.created_date).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric' || '',
          }),
          Action: '',
          back_run: data.back_run,
          lay_run: data.lay_run,
          back: data.back_run ? true : false,
          lay: data.lay_run ? true : false,
        }));
      setCurrentBetsData(currentBetsTableRow || []);
      setFilteredTableData(currentBetsTableRow || []);
    }
  };

  const currentBetsTableHeader = [
    'No',
    'Sports',
    'Event Name',
    'Market Name',
    'Nation',
    'User Rate',
    'Amount',
    'Place Date',
  ];

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();
  
    const filteredData = currentBetsData.filter((data) => {
      const srNoMatch = String(data.No)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const sportsMatch = String(data.Sports)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const eventNameMatch = String(data['Event Name'])
        .toLowerCase()
        .includes(formattedSearchTerm);
      const marketNameMatch = String(data['Market Name'])
        .toLowerCase()
        .includes(formattedSearchTerm);
      const nationMatch = String(data.Nation)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const userRateMatch = String(data.lay_rate)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const amountMatch = String(data.Amount)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const placeDateMatch = String(data['Place Date'])
        .toLowerCase()
        .includes(formattedSearchTerm);
  
      return (
        srNoMatch ||
        sportsMatch ||
        eventNameMatch ||
        marketNameMatch ||
        nationMatch ||
        userRateMatch ||
        amountMatch ||
        placeDateMatch
      );
    });
  
    setFilteredTableData(filteredData);
  };
  

  return (
    <React.Fragment>
      <Stack className='current-bets-main-container'>
        <SearchWithText
          text={'Live casino bets'}
          onSearchClick={(e) => handleSearch(e.target.value)}
        />
      </Stack>
      <Stack className='current-bets-main-container-2'>
        <GenericTable
          tableHeader={currentBetsTableHeader}
          tableRow={filteredTableData}
          currentBetsData={currentBetsData}
          setBetStatus={() => {}}
          fromCasinoBets
        />
      </Stack>
    </React.Fragment>
  );
};
