import React from 'react'
import './depSec.css'
const DepositSection = () => {

    
  return (
    <div className="container">
      <div className="row my-3">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-center flex-grow-1" style={{fontSize:'18px'}}>Deposit Credit</h5>
            <button className="btn">✕</button>
          </div>
        </div>
      </div>
     <div className='select-method-heading'>
     <h5 className='title' style={{fontSize:'17px', fontWeight:'500'}}>Select your deposit method</h5>
     </div>

     <div style={{width: '100%',border: '1px solid black', margin: '0 auto',}}></div>
   
     <div class="row m-0">
   <div class="col-md-3 p-0 col-sm-4 px-1 ">
      <b class="text-danger bankTransferMsg">2% Bonus only on Bank Transfer (IMPS/NEFT/RTGS)</b>
      
   </div>
   <div class="col-md-9 p-0 col-sm-8 px-1 "><b class="text-danger upiTransferMsg">Deposit today get 2% Bonus.</b></div>
</div>

<div className="container-fluid">
<div className="row justify-content-center">
        <div className="col-12">
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-primary"
              style={{
                borderRadius: '50px',
                border: '1px solid #ccc',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                width: '200px', // Adjust the width as needed
                padding: '10px', // Add padding as needed
                position: 'relative', // To position the image within the button
              }}
            >
              <img
                src="path_to_image1.jpg" // Provide the path to your image
                alt="Image 1"
                style={{
                  width: '100%', // Adjust the image size as needed
                  height: '100%', // Adjust the image size as needed
                  position: 'absolute', // Position the image inside the button
                  top: '0',
                  left: '0',
                }}
              />
              Button 1
            </button>
            {/* Repeat the above code for other buttons */}
          </div>
        </div>
      </div>
    </div>


    </div>
  )
}

export default DepositSection