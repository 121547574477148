import axios from "axios";
import { BASE_URL, getToken } from "../utils/constants";
import store from "../redux/store";
import { AXIOS_INSTANCE } from ".";
const { dispatch } = store;

export const getUserDataAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/accmanage/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAccountStatementAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/accmanage/account-log`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserNameAPI = async (username) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/getUserDetail?userName=${username}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
