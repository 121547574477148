import React from 'react';

const SportsBookIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='25'
      viewBox='0 0 20 25'
      fill='none'
    >
      <g clip-path='url(#clip0_3_1499)'>
        <path
          d='M13.7705 12.773C13.5085 12.773 13.2963 12.5456 13.2963 12.265C13.2963 11.9845 13.5085 11.7571 13.7705 11.7571C16.3881 11.7538 18.5096 9.48168 18.5126 6.67789V2.61451H14.2447C13.9827 2.61451 13.7705 2.38709 13.7705 2.10657C13.7705 1.82606 13.9827 1.59863 14.2447 1.59863H18.9869C19.2489 1.59863 19.4611 1.82606 19.4611 2.10657V6.67789C19.4574 10.0425 16.9118 12.7691 13.7705 12.773Z'
          fill='#FFA000'
        />
        <path
          d='M5.69064 12.7736C2.54932 12.7697 0.00364556 10.0431 0 6.6785V2.10718C0 1.82667 0.212332 1.59924 0.474234 1.59924H5.2164C5.47831 1.59924 5.69064 1.82667 5.69064 2.10718C5.69064 2.3877 5.47831 2.61512 5.2164 2.61512H0.948424V6.6785C0.95158 9.48228 3.07289 11.7544 5.69064 11.7578C5.95254 11.7578 6.16487 11.9852 6.16487 12.2657C6.16482 12.5462 5.95254 12.7736 5.69064 12.7736Z'
          fill='#FFA000'
        />
        <path
          d='M12.3411 20.6243L12.1397 20.4156C11.3551 19.6006 11.1395 9.76135 11.1231 8.95236C11.119 8.74654 10.9526 8.58188 10.7487 8.58191H9.25137C9.0478 8.58188 8.88153 8.74611 8.87703 8.95162C8.86131 9.76061 8.64569 19.5983 7.86034 20.4148L7.65894 20.6235C6.63101 21.6918 3.52942 22.4637 3.52942 23.3249C3.52942 23.5337 3.69703 23.7029 3.90376 23.7029H16.0962C16.3029 23.7029 16.4706 23.5337 16.4706 23.3249C16.4706 22.4637 13.3691 21.6918 12.3411 20.6243Z'
          fill='#FFA000'
        />
        <path
          d='M15.4216 0.582764H4.0404C3.77849 0.582764 3.56616 0.810187 3.56616 1.0907V10.4776C3.61062 14.1044 6.34483 17.0329 9.73103 17.0806C13.1173 17.0329 15.8514 14.1044 15.8959 10.4775V1.0907C15.8959 0.810187 15.6835 0.582764 15.4216 0.582764Z'
          fill='#FFC107'
        />
      </g>
      <defs>
        <clipPath id='clip0_3_1499'>
          <rect y='0.582764' width='20' height='23.9416' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SportsBookIcon;
