import React from "react";
import { Stack, Typography } from "@mui/material";
import RulesTabs from "../shared/RulesTabs";
import { FAQText } from "../shared/FAQText";
import { Links } from "../shared/Links";
import "../../assets/styles/css/rules.scss";

export const FAQ = () => {
  const tabLabels = ["Chrome", "Fire Box", "Edge", "Safari"];
  const tabLabels2 = ["Settings for Mobile", "Settings for Desktop"];

  return (
    <Stack className="rules-main-stack-contanier">
      <Typography className="rules-style-text">
        Frequently asked questions (FAQ)
      </Typography>
      <Stack className="rules-inner-satck">
        <FAQText srNo={1} text={"safari browser"} />
        <Links />
      </Stack>
      <Stack className="rules-inner-satck">
        <FAQText srNo={2} text={"private browser"} />
        <RulesTabs tabLabels={tabLabels} defaultTab={0} />
        <Links />
      </Stack>
      <Stack className="rules-inner-satck">
        <FAQText srNo={3} text={"private browser"} />
        <RulesTabs tabLabels={tabLabels2} defaultTab={0} />
        <Links />
      </Stack>
    </Stack>
  );
};
