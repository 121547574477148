import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';
import cross from '../../assets/images/icons/cross.svg';
import React, { useState } from 'react';
import { useEffect } from 'react';
import CustomInput from '../../components/CustomInput';
import CustomModal from '../../components/CustomModal';
import CustomSelect from '../../components/CustomSelect';
import CustomTable from '../../components/Table';
import {
  getCasinoReportHistory,
  getIndividualResultAPI,
} from '../../service/casino';
import { getAccountStatementAPI } from '../../service/UserService';
import {
  bgColor,
  buttonColors,
  exportToCsvReactCsv,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from '../../utils/constants';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import moment from 'moment';
import CasinoResultModal from '../../components/CasinoResultModal/CasinoResultModal';
import {
  changeButtonValuesAPI,
  getButtonValuesAPI,
} from '../../service/AuthService';
import { getService } from '../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function ChangeButtonValue({ onClick }) {
  const toast = useToast();
  const location = useLocation();
  const [from, setFrom] = React.useState(moment().format('YYYY-MM-DD'));
  const [sport, setSport] = React.useState('none');
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedMarket, setSelectedMarket] = React.useState({});
  const [marketData, setMarketData] = React.useState({});
  const [searchValue, setSearchValue] = useState('');
  const [buttons, setButtons] = React.useState({});
  const casinos = [
    {
      label: 'Select Type',
      value: 'none',
    },
    {
      label: 'Dragon Tiger-1 2020',
      image: 'dt20.jpg',
      value: 'DRAGON_TIGER_20',
    },
    {
      label: 'Dragon Tiger 2020 2',
      image: 'dt202.jpg',
      value: 'DRAGON_TIGER_20_2',
    },
    {
      label: 'Dragon Tiger 1 Day',
      image: 'dt6.jpg',
      value: 'DRAGON_TIGER_6',
    },
    {
      label: 'Dragon Tiger Lion',
      image: 'dtl20.jpg',
      value: 'DRAGON_TIGER_LION_20',
    },
    {
      label: 'Teen Patti 2020',
      image: 'teen20.jpg',
      value: 'TEEN_PATTI_20',
    },

    {
      label: 'Teen Patti Test',
      image: 'teen9.jpg',
      value: 'TEEN_9',
    },
    {
      label: 'Teen Patti Open',
      image: 'teen8.jpg',
      value: 'TEEN_8',
    },
    {
      label: 'Casino Queen',
      image: 'queen.jpg',
      value: 'CASINO_QUEEN',
    },
    {
      label: '32 Cards A',
      image: 'card32.jpg',
      value: 'CARD_32',
    },
    {
      label: '32 Cards B',
      image: 'card32eu.jpg',
      value: 'CARD32EU',
    },

    {
      label: 'Poker 20-20',
      image: 'poker20 20.jpg',
      value: 'POKER_20',
    },
    {
      label: 'Lucky 7-A',
      image: 'lucky7.jpg',
      value: 'LUCKY7',
    },
    {
      label: 'Lucky 7-B',
      image: 'lucky7eu.jpg',
      value: 'LUCKY7EU',
    },
    {
      label: 'Andar Bahar',
      image: 'andar-bahar.jpg',
      value: 'AB_20',
    },
    {
      label: 'Andar Bahar 2',
      image: 'andar-bahar2.jpg',
      value: 'ABJ',
    },

    {
      label: 'Bollywood Casino',
      image: 'btable.jpg',
      value: 'BOLLYWOOD_TABLE',
    },
    {
      label: 'Amar Akbar Anthony',
      image: 'aaa.jpg',
      value: 'AAA',
    },
    {
      label: 'Instant Worli',
      image: 'instant worli.jpg',
      value: 'WORLI2',
    },

    {
      label: 'Casino War',
      image: 'casino-war.jpg',
      value: 'CASINO_WAR',
    },
    {
      label: 'Race 20',
      image: 'race-20.png',
      value: 'RACE20',
    },
    {
      label: '5-5 Cricket',
      image: 'five cricket.jpg',
      value: '/live-casino/cricketV3',
    },
    {
      label: 'Cricket 20-20',
      image: 'cmatch20.jpg',
      value: '/live-casino/cmatch20',
    },
    {
      label: 'Baccart',
      image: 'baccarat.jpg',
      value: 'BACCARAT',
    },
    {
      label: 'Baccart 2',
      image: 'baccarat2.jpg',
      link: 'BACCARAT2',
    },
    {
      label: 'Worli Matka',
      image: 'worli.jpg',
      link: 'WORLI2',
    },
    {
      label: 'Super Over',
      image: 'superover.png',
      link: 'SUPEROVER',
    },
    {
      label: '1-Day Poker',
      image: 'poker 1 day.jpg',
      link: 'POKER_1_DAY',
    },
    {
      label: '1 Day Teen Patti',
      image: 'teen.jpg',
      link: 'TEEN',
    },
    {
      label: 'Poker 6 Players',
      image: 'poker6.jpg',
      link: 'POKER_9',
    },
    {
      label: '3 Cards Judgement',
      image: '3 card judgement.jpg',
      link: 'THREE_CARD',
    },
    {
      label: 'Casino Meter',
      image: 'casino meter.jpg',
      link: 'CASINO_WAR',
    },
  ];

  const handleUpdate = (key, value) => {
    setButtons((prev) => ({ ...prev, [key]: value }));
  };
  const getButtonValues = async () => {
    const res = await getButtonValuesAPI();
    if (res.code === 200) {
      setButtons(res.response);
    }
  };
  const handleSave = async () => {
    const res = await changeButtonValuesAPI(buttons);

    if (res.code === 200) {
      toast({
        title: 'Success',
        description: 'Button values updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
    getButtonValues();
  };

  useEffect(() => {
    getButtonValues();
  }, []);
  const getCasinoResult = async () => {
    if (sport === 'none' || !from) {
      return toast({
        title: 'Warning',
        description: 'Please select sport and date',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }
    const res = await getCasinoReportHistory({
      casinoGames: sport,
      date: new Date(from),
      page,
      size,
    });
    if (res.code === 200) {
      setData(res.response.content);
    }
  };
  const search = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == '') return getCasinoResult();

    setData(
      searchSubstringInArrayObjects(
        data,
        ['round_id', 'winner'],
        e.target.value
      )
    );
  };
  const getIndividualResult = async (marketId) => {
    if (marketId) {
      const res = await getIndividualResultAPI(
        getService(location.pathname).port,
        getService(location.pathname).service,
        marketId
      );
      if (res.code === 200) {
        setMarketData(res.response);
      }
    }
  };
  const generateReport = () => {
    exportToCsvReactCsv(data, 'casino_report');
  };
  useEffect(() => {
    getIndividualResult(selectedMarket.marketId);
  }, [selectedMarket]);
  return (
    <Box
      p={{ base: 0, md: 2 }}
      bg={{ base: 'transparent', md: '#fff' }}
      textColor='#A6A6A6'
      pt={{ base: '60px', md: '0' }}
      className='setting-page-main change-button-value-main'
    >
      <Modal
        size={'5xl'}
        isOpen={isResultModalOpen}
        onClose={setIsResultModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <CasinoResultModal
            marketId={isResultModalOpen}
            onClose={setIsResultModalOpen}
          />
        </ModalContent>
      </Modal>
      <Box
        p='2'
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        w='100%'
        gap={'10.98vw'}
        mb='5'
      >
        <Text
          color='#102a6d'
          fontFamily={"'Inter',Helvetica"}
          fontSize={'18px'}
          fontWeight={600}
        >
          Set Button Value
        </Text>
        <img
          src={cross}
          alt=''
          width={'16px'}
          height={'16px'}
          color='#4E4E4E'
          onClick={onClick}
          style={{
            cursor: 'pointer',
          }}
        ></img>
      </Box>

      <Box mt={5} className='col-md-6 change-button-value-box'>
        <Tabs p='0' variant='enclosed'>
          <TabList mb='2' borderBottom='none'>
            <Tab
              _selected={{
                bg: '#102A6D',
              }}
              fontSize='xs'
              borderRadius={'10px 10px 0px 0px'}
              width={'115px'}
              height={'38px'}
              backgroundColor={'#102A6D'}
            >
              <Text
                style={{
                  color: 'white',
                  fontFamily: "'Inter',Helvetica",
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Game Buttons
              </Text>
            </Tab>
            <Tab
              borderRadius={'10px 10px 0px 0px'}
              fontSize='xs'
              _selected={{
                bg: '#102A6D',
              }}
              ml={2}
              width={'115px'}
              height={'38px'}
            >
              <Text
                style={{
                  color: 'white',
                  fontFamily: "'Inter',Helvetica",
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Casino Button
              </Text>
            </Tab>
          </TabList>
          <TabPanels p='0'>
            <TabPanel p='0'>
              <Box
                p='1'
                display='flex'
                justifyContent='space-between'
                style={{
                  backgroundColor: '#C9C9C9',
                  borderRadius: '5px',
                  height: '35px',
                }}
                width={'199%'}
                mb={'5px'}
              >
                <Box w='100%'>
                  <Text
                    style={{
                      color: '#4E4E4E',
                      fontFamily: "'Inter',Helvetica",
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Price Label
                  </Text>
                </Box>
                <Box w='100%'>
                  <Text
                    style={{
                      color: '#4E4E4E',
                      fontFamily: "'Inter',Helvetica",
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Price Value
                  </Text>
                </Box>
              </Box>
              <Grid fontWeight='bold' gap={2} templateColumns='repeat(2,1fr)'>
                <GridItem>
                  <Grid templateColumns='repeat(1,1fr)' style={{ gap: '5px' }}>
                    {Object.keys(buttons).map(
                      (key, index) =>
                        !key.includes('Casino') &&
                        !key.includes('Value') && (
                          <GridItem>
                            <CustomInput
                              onChange={(e) =>
                                handleUpdate(key, e.target.value)
                              }
                              value={buttons[key]}
                            />
                          </GridItem>
                        )
                    )}
                  </Grid>
                </GridItem>
                <GridItem>
                  <Grid templateColumns='repeat(1,1fr)' style={{ gap: '5px' }}>
                    {Object.keys(buttons).map(
                      (key, index) =>
                        !key.includes('Casino') &&
                        !key.includes('Name') && (
                          <GridItem>
                            <CustomInput
                              onChange={(e) =>
                                handleUpdate(key, e.target.value)
                              }
                              value={buttons[key]}
                            />
                          </GridItem>
                        )
                    )}
                  </Grid>
                </GridItem>
              </Grid>
            </TabPanel>
            <TabPanel p='0'>
              <Box
                p='1'
                display='flex'
                justifyContent='space-between'
                style={{
                  backgroundColor: '#C9C9C9',
                  borderRadius: '5px',
                  height: '35px',
                }}
                width={'199%'}
                mb={'5px'}
              >
                <Box w='100%'>
                  <Text
                    style={{
                      color: '#4E4E4E',
                      fontFamily: "'Inter',Helvetica",
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Price Label
                  </Text>
                </Box>
                <Box w='100%'>
                  <Text
                    style={{
                      color: '#4E4E4E',
                      fontFamily: "'Inter',Helvetica",
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    Price Value
                  </Text>
                </Box>
              </Box>
              <Grid fontWeight='bold' gap={2} templateColumns='repeat(2,1fr)'>
                <GridItem>
                  <Grid templateColumns='repeat(1,1fr)' style={{ gap: '5px' }}>
                    {Object.keys(buttons).map(
                      (key, index) =>
                        key.startsWith('buttonNameCasino') && (
                          <GridItem>
                            <CustomInput
                              onChange={(e) =>
                                handleUpdate(key, e.target.value)
                              }
                              value={buttons[key]}
                            />
                          </GridItem>
                        )
                    )}
                  </Grid>
                </GridItem>
                <GridItem>
                  <Grid templateColumns='repeat(1,1fr)' style={{ gap: '5px' }}>
                    {Object.keys(buttons).map(
                      (key, index) =>
                        key.startsWith('buttonValueCasino') && (
                          <GridItem>
                            <CustomInput
                              onChange={(e) =>
                                handleUpdate(key, e.target.value)
                              }
                              value={buttons[key]}
                            />
                          </GridItem>
                        )
                    )}
                  </Grid>
                </GridItem>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box
          display={'flex'}
          justifyContent={'center'}
          mt={'10px'}
          alignItems={'center'}
          style={{
            width: '200%',
          }}
        >
          <Button
            bg='#102A6D'
            onClick={handleSave}
            borderRadius={'10px'}
            height={'50px'}
            style={{
              width: '200%',
            }}
          >
            <Text
              fontFamily={"'Inter',Helvetica"}
              fontSize={'16px'}
              fontWeight={600}
              color={'#fff'}
            >
              Submit
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
