import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Evolution from "../../../src/assets/images/icons/Evolution.png";
import Ezg from "../../../src/assets/images/icons/ezg.svg";
import "../../../src/assets/styles/css/cardItem.scss";
import { images, images2 } from "./index.utils";
import { useNavigate } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import "./cardItem.css";
import { SimpleGrid } from "@chakra-ui/react";

export const CardItem = () => {
  const navigate = useNavigate();
  const { sportName } = useParams();
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(0);

  const redirectionPaths = {
    0: "/games/cricket",
    1: "/games/soccer",
    2: "/games/tennis",
    3: "/live-casino",
    4: "/card-games",
    // add other paths for images
  };

  const handleRedirection = (index) => {
    const path = redirectionPaths[index] || "/";
    window.location.href = path;
    setSelectedImage(index);
  };

  useEffect(() => {
    const isHomePage = location.pathname === "/";
    if (isHomePage) {
      setSelectedImage(null);
    }
  }, [location.pathname]);

  return (
    <Stack className="card-mainStack">
      <SimpleGrid className="card-stack1" gap={1} columns={{ base: 3, md: 5 }}>
        {images.map((item, index) => (
          <Stack
            key={index}
            className={`card-innerStack ${
              location.pathname.includes(item.sport) ? "selected" : ""
            }`}
          >
            <img
              src={item.img}
              alt={`images-${index}`}
              className="card-img"
              onClick={() => handleRedirection(index)}
            />
          </Stack>
        ))}
      </SimpleGrid>
      {/* <Stack className="card-stack1" gap={1} columns={{ base: 1, md: 2 }} mt={1}>
        <img
          src={Evolution}
          alt="new"
          className="card-img-1 card-innerStack"
          style={{ width: "338px", height: "60px" }}
        />
        <img
          src={Ezg}
          alt="new1"
          className="card-img-2 card-innerStack"
          style={{ width: "338px", height: "60px" }}
        />
        {images2.map((item, index) => (
          <Stack
            key={index}
            className={`card-innerStack ${
              location.pathname.includes(item.sport) ? "selected" : ""
            }`}
          >
            <img
              src={item.img}
              alt={`images-${index}`}
              className="card-img"
              // onClick={() => handleRedirection(index)}
            />
          </Stack>
        ))}
      </Stack> */}
    </Stack>
  );
};
