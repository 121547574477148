import {
  Box,
  Flex,
  HStack,
  Link,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resendOTP, verifyMFACode } from "./Service";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

const LoginAuth = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [otpValue, setOtpValue] = useState("");

  const handleChange = (value) => {
    setOtpValue(value);
  };
  const handleComplete = (value) => {
    verifyMFACodeHandler(value);
  };
  const verifyMFACodeHandler = async (otp) => {
    const res = await verifyMFACode(otp, searchParams.get("user"));

    if (res?.data?.code === 200) {
      const resData = res.data.response;
      localStorage.setItem("token", resData?.token);
      localStorage.setItem("user", JSON.stringify(resData));
      dispatch({
        type: "accountData/setUserData",
        payload: resData,
      });
      navigate("/");
    } else {
      toast({
        title: "Error",
        description: "Invalid code",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setOtpValue("");
    }
  };

  useEffect(() => {
    toast({
      title: "OTP",
      description: "Sucessfully Code Generate.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  }, []);

  const resendOTPHandler = async () => {
    const res = await resendOTP(searchParams.get("user"));

    if (res.data.code === 200) {
      toast({
        title: "OTP",
        description: "Sucessfully Code Generate.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "OTP",
        description: res.data.status,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
  };

  return (
    <>
      <div className="login">
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
          <Stack spacing={2} fontSize="sm" mx={"auto"} px={6}>
            <Stack align={"center"}>
              <img
                className="logo"
                alt="logo"
                src={require("../../assets/img/logo-dummy.png")}
              />
            </Stack>
            <Box
              rounded={"lg"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
              className="login-auth-2-box"
            >
              <Text
                display="flex"
                as="h4"
                align="center"
                mb="3"
                color="#0088CC"
                fontSize="1.5rem"
                justifyContent="center"
                alignItems="center"
                className="title-text"
              >
                Security Code Verification Using Telegram App{" "}
              </Text>
              <Text className="sub-title">
                Enter 6-digit code from your telegram bot{" "}
                <Link onClick={() => resendOTPHandler()}>Resend Code</Link>
              </Text>
              <Box className="otp-box">
                <HStack>
                  <PinInput
                    otp
                    onChange={handleChange}
                    onComplete={handleComplete}
                    value={otpValue}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </Box>
            </Box>
          </Stack>
        </Flex>
      </div>
    </>
  );
};

export default LoginAuth;
