import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { TextWithImage } from '../../components/shared/TextWithImage';
import play from '../../../src/assets/images/icons/play.svg';
import BetRickTable from '../../components/BetRickTable';
import Cricket from '../../../src/assets/images/icons/cricket1.svg';
import { IconLabel } from '../../components/shared/IconLabel';
import heart from '../../../src/assets/images/icons/heart.svg';
import casino from '../../../src/assets/images/icons/casino1.svg';
import Football from '../../../src/assets/images/icons/dark-football.svg';
import upcoming from '../../../src/assets/images/icons/upcoming.svg';
import CardGameImages from '../../components/CardGameImage';
import LiveCasinoImages from '../../components/LiveCasinoImage';
import { BetSlip } from '../../components/BetSlip';
import '../../../src/assets/styles/css/home.scss';
import BetRickUpcomingTable from '../../components/BetrickUpcomingTable';
import { rowData, upcomingData } from '../../utils/data';
import GameList from '../dashboard/GameList';
import TabsContent from '../home/tabsContent';
import { Box, Flex, Text } from '@chakra-ui/react';
import cricket from '../../assets/images/icons/Group.png';
import FootballMenuIcon from '../../components/Icons/FootballMenu';
import TennisMenuIcon from '../../components/Icons/TennisMenu';
import FootballIcon from './FootballIcon';
import { height, width } from '@mui/system';
import InPlayMatchListing from '../home/InPlayMatchListing';
import UpcomingMatchListing from '../home/UpcomingMatchListing';

export const LandingPage = () => {
  const getSportsImage = (sportName) => {
    switch (sportName) {
      case 'cricket':
        return (
          <img
            src={cricket}
            alt='Cricket'
            width={'24.34px'}
            style={{ height: '24px' }}
          />
        );
      case 'soccer':
        return <FootballMenuIcon />;
      case 'tennis':
        return <TennisMenuIcon />;
      default:
        return null;
    }
  };

  useEffect(() => {
    document.body.classList.add('landing-page');
    return () => document.body.classList.remove('landing-page');
  }, []);

  return (
    <Stack className='main-home' bg='#fff'>
      <Stack direction={'row'} gap={1} className='main-home-stack-1'>
        <Stack gap={2} className='home-stack-content'>
          <Stack className='home-stack-content-1'>
            <TextWithImage src={play} text={'In Play'} />
            <Stack height={'100%'}>
              <Flex flexDir='column' gap='20px' mt='20px'>
                <Box>
                  {' '}
                  <Flex>
                    {getSportsImage('cricket')}
                    <Text ml={2} className='sports-name'>
                      Cricket
                    </Text>
                  </Flex>
                  <InPlayMatchListing sports={'cricket'} />
                </Box>
                <Box>
                  {' '}
                  <Flex>
                    <FootballIcon />
                    <Text ml={2} className='sports-name'>
                      Football
                    </Text>
                  </Flex>
                  <InPlayMatchListing sports={'soccer'} />
                </Box>
                <Box>
                  {' '}
                  <Flex>
                    {getSportsImage('tennis')}
                    <Text ml={2} className='sports-name'>
                      Tennis
                    </Text>
                  </Flex>
                  <InPlayMatchListing sports={'tennis'} />
                </Box>
              </Flex>

              <GameList />
            </Stack>
          </Stack>
          <Stack spacing={2} className='home-stack-content-2'>
            {/* <IconLabel iconSrc={heart} label={'Card Game'} /> */}
            <TextWithImage src={heart} text={'Card Game'} />
          </Stack>
          <CardGameImages />
          <Stack spacing={2} className='home-satck-content-3'>
            {/* <IconLabel iconSrc={casino} label={'Live Casino'} /> */}
            <TextWithImage src={casino} text={'Live Casino'} />
          </Stack>
          <LiveCasinoImages />
          <Stack className='home-stack-content-4'>
            <TextWithImage src={upcoming} text={'Upcoming Events'} />
            <Stack className='home-stack-content-5-1' height={'100%'}>
              <Flex flexDir='column' gap='20px' mt='20px'>
                <Box>
                  {' '}
                  <Flex>
                    {getSportsImage('cricket')}
                    <Text ml={2} className='sports-name'>
                      Cricket
                    </Text>
                  </Flex>
                  <UpcomingMatchListing sports={'cricket'} />
                </Box>
                <Box>
                  {' '}
                  <Flex>
                    <FootballIcon />
                    <Text ml={2} className='sports-name'>
                      Football
                    </Text>
                  </Flex>
                  <UpcomingMatchListing sports={'soccer'} />
                </Box>
                <Box>
                  {' '}
                  <Flex>
                    {getSportsImage('tennis')}
                    <Text ml={2} className='sports-name'>
                      Tennis
                    </Text>
                  </Flex>
                  <UpcomingMatchListing sports={'tennis'} />
                </Box>
              </Flex>
            </Stack>
          </Stack>
        </Stack>
        {/* <BetSlip /> */}
      </Stack>
    </Stack>
  );
};
