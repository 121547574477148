import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import CustomInput from "../components/Input";
import { Encrypt } from "../utils/constants";
import { useState } from "react";
import { registerAPI, updatePasswordAPI } from "../service/AuthService";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo.svg";
import "./../components/login/login.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const toast = useToast();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const UpdatePasswordHandler = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const res = await registerAPI({
      oldPassword,
      newPassword: Encrypt(newPassword),
    });
    if (res.code === 200) {
      navigate("/");
      toast({
        title: "Success",
        description: "Success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (res.code === 403) {
      toast({
        title: "Error",
        description: res?.response || "Somthing went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (res.code === 401) {
      toast({
        title: "Error",
        description: res?.response || "Unauthorized, Enter Correct credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (res.code === 500) {
      toast({
        title: "Error",
        description: res?.response || "Unauthorized, Enter Correct credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: res?.error || "Something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <div className="login">
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
          <Stack spacing={2} fontSize="sm" mx={"auto"} maxW={"422px"}>
            <Stack align={"center"} style={{ marginTop: "20px" }}>
              <img className="logo" alt="logo" src={Logo} />
            </Stack>
            <Box
              rounded={"lg"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
            >
              <Text className="login-text">Change Password</Text>
              <Stack spacing={1}>
                <CustomInput
                  type="number"
                  label="Mobile"
                  placeholder={"Mobile Number"}
                  name="mobile"
                  // onChange={(e) => setOldPassword(e.target.value)}
                  // rightIcon={
                  //   showOldPassword ? (
                  //     <FaEyeSlash onClick={() => setShowOldPassword(false)} />
                  //   ) : (
                  //     <FaEye onClick={() => setShowOldPassword(true)} />
                  //   )
                  // }
                />
                <CustomInput
                  type={showNewPassword ? "text" : "password"}
                  label="New Password"
                  placeholder={"New Password"}
                  name="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  rightIcon={
                    showNewPassword ? (
                      <FaEyeSlash onClick={() => setShowNewPassword(false)} />
                    ) : (
                      <FaEye onClick={() => setShowNewPassword(true)} />
                    )
                  }
                />
                <CustomInput
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirm Password"
                  placeholder={"Confirm Password"}
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  rightIcon={
                    showConfirmPassword ? (
                      <FaEyeSlash
                        onClick={() => setShowConfirmPassword(false)}
                      />
                    ) : (
                      <FaEye onClick={() => setShowConfirmPassword(true)} />
                    )
                  }
                />
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={UpdatePasswordHandler}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor={"#102a6d"}
                    borderRadius={"10px"}
                    height={"44px"}
                  >
                    <Text
                      style={{
                        fontFamily: "'Inter',Helvetica",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      Update Password
                    </Text>
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </div>
    </>
  );
}
