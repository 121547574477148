import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { SearchWithText } from '../shared/SearchWithText';
import CustomTab from '../shared/Tabs';
import { GenericTable } from '../shared/GenericTable';
import RadioGroups from '../shared/RadioGroups';
import '../../assets/styles/css/current.scss';
import { getCurrentBets, unSetteledBetsAPI } from '../../service/AuthService';

export const CurrentBets = () => {
  const [currentBetsData, setCurrentBetsData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [betStatus, setBetStatus] = useState('MATCHED');

  const fetchCurrentBetsData = useCallback(async () => {
    try {
      const payLoad = {
        betStatus: betStatus,
        pageNumber: 0,
        pageSize: 100,
        search: '',
      };
      const response = await unSetteledBetsAPI(payLoad);
      const currentBetsTableRow = response.response.content.map(
        (data, index) => ({
          No: index + 1,
          Sports: data.event_type || '',
          'Event Name': data.event_name || '',
          'Market Name': data.marketName || '',
          Nation: data.runner_name || '',
          'User Rate': data.lay_rate || '',
          Amount: data.amount || '',
          'Place Date': new Date(data.created_date).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric' || '',
          }),
          Action: '',
          back_run: data.back_run,
          lay_run: data.lay_run,
          back: data.back_run ? true : false,
          lay: data.lay_run ? true : false,
        })
      );
      setCurrentBetsData(currentBetsTableRow || []);
      setFilteredTableData(currentBetsTableRow || []);
    } catch (error) {
      console.error('Error fetching current bets data:', error);
    }
  }, [betStatus]);
  useEffect(() => {
    fetchCurrentBetsData();
  }, [fetchCurrentBetsData]);

  const currentBetsTableHeader = [
    'No',
    'Sports',
    'Event Name',
    'Market Name',
    'Nation',
    'User Rate',
    'Amount',
    'Place Date',
  ];

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();
  
    const filteredData = currentBetsData.filter((data) => {
      const srNoMatch = String(data.No)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const sportsMatch = String(data.Sports)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const eventNameMatch = String(data['Event Name'])
        .toLowerCase()
        .includes(formattedSearchTerm);
      const marketNameMatch = String(data['Market Name'])
        .toLowerCase()
        .includes(formattedSearchTerm);
      const nationMatch = String(data.Nation)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const userRateMatch = String(data['User Rate'])
        .toLowerCase()
        .includes(formattedSearchTerm);
      const amountMatch = String(data.Amount)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const placeDateMatch = String(data['Place Date'])
        .toLowerCase()
        .includes(formattedSearchTerm);
  
      return (
        srNoMatch ||
        sportsMatch ||
        eventNameMatch ||
        marketNameMatch ||
        nationMatch ||
        userRateMatch ||
        amountMatch ||
        placeDateMatch
      );
    });
  
    setFilteredTableData(filteredData);
  };
  
  

  return (
    <React.Fragment>
      <Stack className='current-bets-main-container'>
        <SearchWithText
          text={'Current bets'}
          onSearchClick={(e) => handleSearch(e.target.value)}
        />

        <RadioGroups onChange={(e) => setBetStatus(e.target.value)} />
      </Stack>
      <Stack className='current-bets-main-container-2'>
        <GenericTable
          tableHeader={currentBetsTableHeader}
          tableRow={filteredTableData}
          isCurrentBets={true}
          currentBetsData={currentBetsData}
          setBetStatus={setBetStatus}
        />
      </Stack>
    </React.Fragment>
  );
};
