import React from "react";
import NewImage from "../../../src/assets/images/icons/newImage.svg";
import FooterImage from "../../../src/assets/images/icons/footerImage.svg";
import "../../../src/assets/styles/css/footer.scss";
import { texts } from "./index.utils";
import { Text, Flex } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const FooterMui = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <Flex className="footer-mainStack" direction={{ base: "column", md: "column" }}>
      <Flex className="footer-innerStack" direction={{ base: "column", md: "row" }}>
        <Text className="footer-text" onClick={() => navigateTo("/#")}>
          Introduction
        </Text>
        <Text className="footer-text" onClick={() => navigateTo("/#")}>
          How to Register
        </Text>
        <Text className="footer-text" onClick={() => navigateTo("/#")}>
          How to Deposit
        </Text>
        <Text className="footer-text" onClick={() => navigateTo("/#")}>
          How to Use Bonus
        </Text>
        <Text className="footer-text" onClick={() => navigateTo("/FAQ")}>
          FAQs
        </Text>
      </Flex>
      <Flex className="footer-innerStack1" direction={{ base: "column", md: "column" }} align="center">
        <img src={NewImage} alt="new3" style={{ maxWidth: "100%", height: "auto" }} />
        <Text className="footer-text">
          Pawan is a limited liability company incorporated under the laws of
          Curacao Players are requested not to contact any untrusted sources for
          LOTUS 365 accounts as this is an online site and they can only
          register independently without any agents. Only deposit through the
          account details generated by the system or provided by our official
          support team.
        </Text>
      </Flex>
      <Flex className="footer-innerStack2" justify="center">
        <img src={FooterImage} alt="footer" style={{ maxWidth: "100%", height: "auto" }} />
      </Flex>
    </Flex>
  );
};
