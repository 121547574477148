import React, { useEffect, useState } from 'react';
import {
  Wrap,
  WrapItem,
  Center,
  Flex,
  Box,
  Spacer,
  Text,
  Grid,
  GridItem,
  CircularProgress,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import ExportTabs from '../home/exportTabs';
import { BiFootball } from 'react-icons/bi';
import { AiTwotoneStop } from 'react-icons/ai';
import { MdSportsCricket } from 'react-icons/md';
import Footer from '../../includes/footer';
import DasRside from '../../includes/dasRightSideBar';
import { dashboardBannerAPI, getSignedUrlAPI } from '../../service/AuthService';
import ImageSlider from '../../components/imageSlider';
import { ASSETS_URL, pokerGameList } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cricket from '../../assets/images/icons/Group.png';
import FootballMenuIcon from '../../components/Icons/FootballMenu';
import TennisMenuIcon from '../../components/Icons/TennisMenu';
import {
  getCricketLiveMatches,
  getLiveMatches,
  getTennisSoccerLiveMatches,
} from '../../service/cricket';

const Dashboard = ({ getMatches }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { sportName } = useParams();
  const { isDataLoaded } = useSelector((state) => state.gameData);
  const [images, setImages] = useState([]);
  const [homeImages, setHomeImages] = useState([]);
  const [sliderWidth, setSliderWidth] = useState(null);
  var newHomeImages = [];
  const getBannerImages = async () => {
    var res = await dashboardBannerAPI();
    console.log('banner data', res);
    if (res.status === 200) {
      let data = res.data.response;

      for (let i = 0; i < data.length; i++) {
        let res = await getSignedUrlAPI(data[i].bannerUrl);
        //array store saariimages ye images mai box welcome banner
        console.log('SignedUrl', res);
        if (res?.data?.code === 200) {
          newHomeImages.push(res.data?.response);
          data[i].bannerUrl = res.data?.response;
        }
      }
      //setHomeImages(data.map((item) => item.bannerUrl));
      setHomeImages((prevHomeImages) => [...prevHomeImages, ...newHomeImages]);
      setImages(data.map((item) => item.bannerUrl));
    }
  };
  useEffect(() => {
    getBannerImages();
  }, []);
  useEffect(() => {
    console.log('homeImagesCloud', homeImages);
  }, [homeImages]);

  const style = {
    backgroundImage:
      'url(https://sitethemedata.com/v94/static/front/img/wave2.svg)',
    width: '20%',
    height: '50px',
    margin: 'auto',
    padding: '10px',
    borderRadius: '10px',
  };
  const style2 = {
    width: '50%',
    backgroundColor: '#F18521',
    borderRadius: '7px',
    height: '40px',
    backgroundImage:
      'url(https://sitethemedata.com/v94/static/front/img/wave2.svg)',
    margin: 'auto',
    padding: '10px',
    marginBottom: '5px',
  };

  const getSportsImage = (sportName) => {
    switch (sportName) {
      case 'cricket':
        return (
          <img
            src={cricket}
            alt='Cricket'
            width={'24.34px'}
            style={{ height: '24px' }}
          />
        );
      case 'soccer':
        return <FootballMenuIcon />;
      case 'tennis':
        return <TennisMenuIcon />;
      default:
        return null;
    }
  };

  const getCricketLiveMatchesHandler = async () => {
    const res = await getCricketLiveMatches();

    if (res.status === 200) {
      dispatch({
        type: 'gameData/setCricketGames',
        payload: res.data.response,
      });
    }
  };
  const getTennisSoccerLiveMatchesHandler = async () => {
    const res = await getTennisSoccerLiveMatches('tennis');

    if (res.status === 200) {
      if (sportName == 'soccer') {
        dispatch({
          type: 'gameData/setSoccerGames',
          payload: res?.data?.response?.soccerAllMatchesResponseDTOS,
        });
      } else {
        dispatch({
          type: 'gameData/setTennisGames',
          payload: res?.data?.response?.tennisAllMatchesResponseDTOS,
        });
      }
    }
  };

  useEffect(() => {
    if (sportName === 'cricket') {
      getCricketLiveMatchesHandler();
    } else {
      getTennisSoccerLiveMatchesHandler();
    }
  }, []);

  return (
    <>
      <Box padding={'5px'}>
        {!isDataLoaded && (
          <div className='loader-box'>
            <Spinner style={{width:"30px", height:"30px"}} />
          </div>
        )}
        <>
          <Flex
            className='sports-tab-main'
            justify='space-between'
            align='center'
          >
            <Flex>
              {getSportsImage(sportName)}
              <Text ml={2} className='sports-name'>
                {sportName === 'soccer'
                  ? 'Football'
                  : sportName.charAt(0).toUpperCase() + sportName.slice(1)}
              </Text>
            </Flex>
            <Text className='batting-text'>Betting from 1min before start</Text>
          </Flex>
          <ExportTabs />
        </>
      </Box>
    </>
  );
};

export default Dashboard;
