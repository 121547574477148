import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment'
import Search from "../../../assets/images/icons/search.svg";
import "../../../assets/styles/css/search.scss";

const SearchTextField = ({onSearchClick}) => {
  return (
    <TextField
      className="text-field"
      placeholder="Search"
      variant="outlined"
      onChange={onSearchClick}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <img src={Search} alt="Search Icon" className="search-img" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchTextField;
