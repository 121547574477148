import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import PlaceBet from "../../game/PlaceBet";
import "./css/style.css";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import LastResults from "../../../components/LastResults";
export default function Worli() {
  const odds_ = useSelector((state) => state.gameData.worliOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();

  const CustomButton = ({ text }) => (
    <Button size="sm" my="2" className="casinoButton">
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = "DRAGON_TIGER_20";
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box h="60vh" mb="2" pos="relative" bg="black">
          {odds && odds.cards && (
            <Box top="8" p="2" pos="absolute" display="flex" bg="black">
              <img
                style={{
                  marginRight: "5px",
                }}
                className="cardimg"
                src={require(`../../../assets/img/casino/${odds?.cards[0][0]}.png`)}
              />
              <img
                className="cardimg"
                src={require(`../../../assets/img/casino/${odds?.cards[0][1]}.png`)}
              />
            </Box>
          )}
          <div class="market-title">Worli Matka</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3054`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
        </Box>
        <div className="casino-detail">
          <div className="casino-tabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a href="#single" data-toggle="tab" className="nav-link active">
                  Single
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#pana" data-toggle="tab" className="nav-link">
                  Pana
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#sp" data-toggle="tab" className="nav-link">
                  SP
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#dp" data-toggle="tab" className="nav-link">
                  DP
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#trio" data-toggle="tab" className="nav-link">
                  Trio
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#cycle" data-toggle="tab" className="nav-link">
                  Cycle
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#motorsp" data-toggle="tab" className="nav-link">
                  Motor SP
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#card56" data-toggle="tab" className="nav-link">
                  56 Charts
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#card64" data-toggle="tab" className="nav-link">
                  64 Charts
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#abr" data-toggle="tab" className="nav-link">
                  ABR
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#commonsp" data-toggle="tab" className="nav-link">
                  Common SP
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#commondp" data-toggle="tab" className="nav-link">
                  Common DP
                </a>
              </li>{" "}
              <li className="nav-item">
                <a href="#colordp" data-toggle="tab" className="nav-link">
                  Color DP
                </a>
              </li>
            </ul>
          </div>{" "}
          <div className="casino-box tab-content">
            <div id="single" className="tab-pane active single">
              <div className="worlibox">
                <div className="worli-left">
                  <div className="worli-box-title">
                    <b>9.5</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-title">
                    <b>9.5</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">LINE 1</span>{" "}
                      <span className="d-block">1|2|3|4|5</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">ODD</span>{" "}
                      <span className="d-block">1|3|5|7|9</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">LINE 2</span>{" "}
                      <span className="d-block">6|7|8|9|0</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">EVEN</span>{" "}
                      <span className="d-block">2|4|6|8|0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="pana" className="tab-pane fade pana">
              <div className="worlibox">
                <div className="worli-full">
                  <div className="worli-box-title">
                    <b>SP:140 | DP:240 | TP:700</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/**/}
            </div>{" "}
            <div id="sp" className="tab-pane fade sp">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>140</b>
                </div>{" "}
                <div className="worli-left">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">SP ALL</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="dp" className="tab-pane fade dp">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>240</b>
                </div>{" "}
                <div className="worli-left">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">DP ALL</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="trio" className="tab-pane fade trio">
              <div className="worlibox">
                <div className="worli-full">
                  <div className="worli-box-title">
                    <b>700</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">ALL TRIO</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="cycle" className="tab-pane fade cycle">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>&nbsp;</b>
                </div>{" "}
                <div className="worli-full">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/**/}
            </div>{" "}
            <div id="motorsp" className="tab-pane fade motorsp">
              <div className="worlibox">
                <div className="worli-full">
                  <div className="worli-box-title">
                    <b>0</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/**/}
            </div>{" "}
            <div id="card56" className="tab-pane fade card56">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>140</b>
                </div>{" "}
                <div className="worli-left">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">56 ALL</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="card64" className="tab-pane fade card64">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>140</b>
                </div>{" "}
                <div className="worli-left">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">64 ALL</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="abr" className="tab-pane fade abr">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>140</b>
                </div>{" "}
                <div className="worli-left">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">A</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">B</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">R</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">AB</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">AR</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">BR</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">ABR</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">ABR CUT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="commonsp" className="tab-pane fade commonsp">
              <div className="worlibox">
                <div className="worli-full">
                  <div className="worli-box-title">
                    <b>140</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="commondp" className="tab-pane fade commondp">
              <div className="worlibox">
                <div className="worli-full">
                  <div className="worli-box-title">
                    <b>240</b>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="colordp" className="tab-pane fade colordp">
              <div className="worlibox">
                <div className="worli-box-title">
                  <b>240</b>
                </div>{" "}
                <div className="worli-left">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">1</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">2</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">3</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">4</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">5</span>
                    </div>
                  </div>{" "}
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">6</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">7</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">8</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">9</span>
                    </div>{" "}
                    <div className="worli-odd-box back">
                      <span className="worli-odd">0</span>
                    </div>
                  </div>
                </div>{" "}
                <div className="worli-right">
                  <div className="worli-box-row">
                    <div className="worli-odd-box back">
                      <span className="worli-odd">COLOR DP ALL</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/**/} {/**/} {/**/}
        </div>

        {/*  <Grid templateColumns="repeat(2,1fr)">
          <GridItem>
            <Text textAlign="center" fontWeight="bold">
              9
            </Text>
            <Grid templateColumns="repeat(5,2fr)">
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  0
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  1
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  2
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  3
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  4
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  5
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  6
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  7
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  8
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  9
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Text textAlign="center" fontWeight="bold">
              9
            </Text>
            <Grid templateColumns="repeat(2,2fr)" size="lg">
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  LINE 1
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  1|2|3|4|5
                </Text>
              </GridItem>
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  ODD
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  1|3|5|7|9
                </Text>
              </GridItem>
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  LINE 2
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  6|7|8|9|0
                </Text>
              </GridItem>
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  EVEN
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  2|4|6|8|0
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid> */}
        <LastResults eventId="DRAGON_TIGER_20" />
      </Box>

      <PlaceBet game="casino" eventId="DRAGON_TIGER_20" />
    </Box>
  );
}
